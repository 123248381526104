/*
    Header for the unauthenticated users
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
} from '@material-ui/core';
import Logo from '../../images/Rosemark_reverse.png';

const imageStyle = {
  maxWidth: '60%',
  maxHeight: 'auto',
}

const useStyles = makeStyles(theme => ({
    headerContainer: {
        display: 'flex',
        width: '100%',
        padding: 30,
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'center'
    }
}))

export default function PreLogHeader(props) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container className={classes.headerContainer}>
                <img src={Logo} alt="Rosemark logo" style={imageStyle}/>
            </Container>
        </React.Fragment>
    );
}