/*
    Component that handles the specification of run(s)
    and submits them to the backend. Once it does, it renders the
    ComputeModal component that tells users to wait.
*/

import React, { useReducer, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import axios from 'axios';
import clsx from 'clsx';
import qs from 'qs';

import VariableModal from './VariableModal';
import ComputeModal from './ComputeModal';

import {paths} from '../../PathContext';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    tabContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 100,
        marginRight: 100,
    },
    runBoxMainRoot: {
        width: '100%',
        marginTop: 30,
        border: '2px solid #d8d8d8',
        borderRadius: 10,
        padding: '40px 50px 30px 50px',
    },
    runBoxSummary: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    runBoxDetail: {
        width: '100%',
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
    },
    runBoxDetailHeading: {
        width: '100%',
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 10,
        padding: 0,
    },
    runBoxDetailRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginBottom: 15,
    },
    runBoxFlex: {
        flex: 1,
        marginRight: 20,
    },
    runBoxFlexRightFlush: {
        flex: 0.5,
        marginRight: 20,
        justifyContent: 'end',
    },
    runBoxNoFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 20,
        marginRight: 20,
    },
    runInput: {
        margin: 0,
        width: '100%',
    },

    expandIconButton: {
        transform: 'rotate(180deg)',
    },
    advancedIconButton: {
        marginLeft: 10,
        marginRight: 10,
    },

    submitButtonWrapper: {
        textTransform: 'none',
        fontSize: 14,
        padding: '10px 30px',
        backgroundColor: theme.palette.dark.main,
        '&:hover': {
            backgroundColor: theme.palette.dark.light
        },
        minWidth: 200,
    },
    addRunButton: {
        marginTop: 50,
        fontSize: 21,
        textTransform: 'none',
        fontWeight: 700,
        padding: '10px 30px 10px 30px'
    },
    containedSubmitButton: {
        minHeight: 50,
        marginBottom: 50,
        marginTop: 20,
    },
}))

export default function RunDesignComponent(props) {
    const classes = useStyles()

    const {metricsDict, profileDict, variableHeaders, clusterDict, projectID, setTabValue, duplicatedRun} = props;

    const [ computeRun, setComputeRun ] = useState(false);
    const [ onNumber, setOnNumber ] = useState(0);
    const [ internalError, setInternalError ] = useState('')

    const [runList, listDispatcher] = useReducer((runList, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...runList, ''];
            case 'remove': {
                copy = runList.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = runList.slice()
                copy[index] = value
                return copy
            }
            case 'duplicate': {
                return [...runList, runList[index]]
            }
            default:
                return runList
        }
    }, [])

    const [clusterNum, clusterNumDispatcher] = useReducer((clusterNum, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...clusterNum, ''];
            case 'remove': {
                copy = clusterNum.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = clusterNum.slice()
                copy[index] = value
                return copy
            }
            case 'duplicate': {
                return [...clusterNum, clusterNum[index]]
            }
            default:
                return clusterNum
        }
    }, [])

    const [toggleList, toggleDispatcher] = useReducer((toggleList, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...toggleList, false];
            case 'remove': {
                copy = toggleList.slice()
                copy.splice(index, 1);
                return copy
            }
            case 'toggle': {
                copy = toggleList.slice()
                copy[index] = !copy[index]
                return copy
            }
            default:
                return toggleList
        }
    }, [])

    const [openVar, openVarDispatcher] = useReducer((openVar, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...openVar, false];
            case 'remove': {
                copy = openVar.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = openVar.slice()
                copy[index] = !copy[index]
                return copy
            }
            default:
                return openVar
        }
    }, [])

    const [openMetrics, openMetricsDispatcher] = useReducer((openMetrics, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...openMetrics, false];
            case 'remove': {
                copy = openMetrics.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = openMetrics.slice()
                copy[index] = !copy[index]
                return copy
            }
            default:
                return openMetrics
        }
    }, [])

    const [openProfile, openProfileDispatcher] = useReducer((openProfile, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...openProfile, false];
            case 'remove': {
                copy = openProfile.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = openProfile.slice()
                copy[index] = !copy[index]
                return copy
            }
            default:
                return openProfile
        }
    }, [])

    const [profileSet, profileSetDispatcher] = useReducer((profileSet, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...profileSet, []];
            case 'remove': {
                copy = profileSet.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = profileSet.slice()
                copy[index] = value
                return copy
            }
            case 'filter': {
                copy = profileSet.slice();
                copy[index] = copy[index].filter(function(val) {return value.indexOf(val) === -1;});
                return copy
            }
            case 'duplicate': {
                return [...profileSet, profileSet[index]]
            }
            default:
                return profileSet
        }
    }, [])

    const [metricsSet, metricsSetDispatcher] = useReducer((metricsSet, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...metricsSet, []];
            case 'remove': {
                copy = metricsSet.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = metricsSet.slice()
                copy[index] = value
                return copy
            }
            case 'filter': {
                copy = metricsSet.slice();
                copy[index] = copy[index].filter(function(val) {return value.indexOf(val) === -1;});
                return copy
            }
            case 'duplicate': {
                return [...metricsSet, metricsSet[index]]
            }
            default:
                return metricsSet
        }
    }, [])

    const [clusterSet, clusterSetDispatcher] = useReducer((clusterSet, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...clusterSet, []];
            case 'remove': {
                copy = clusterSet.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = clusterSet.slice()
                copy[index] = value
                profileSetDispatcher({type: 'filter', index: index, value: value})
                return copy
            }
            case 'duplicate': {
                return [...clusterSet, clusterSet[index]]
            }
            default:
                return clusterSet
        }
    }, [])

    const [clusterAlphas, clusterAlphasDispatcher] = useReducer((clusterAlphas, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...clusterAlphas, 100];
            case 'remove': {
                copy = clusterAlphas.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = clusterAlphas.slice()
                copy[index] = value
                return copy
            }
            case 'duplicate': {
                return [...clusterAlphas, clusterAlphas[index]]
            }
            default:
                return clusterAlphas
        }
    }, [])

    const [descriptions, descriptionsDispatcher] = useReducer((descriptions, {type, index, value}) => {
        var copy = []
        switch (type) {
            case 'add':
                return [...descriptions, ''];
            case 'remove': {
                copy = descriptions.slice();
                copy.splice(index, 1);
                return copy
            }
            case 'update': {
                copy = descriptions.slice()
                copy[index] = value
                return copy
            }
            case 'duplicate': {
                return [...descriptions, descriptions[index]]
            }
            default:
                return descriptions
        }
    }, [])

    // when a new button is pressed
    const addNewRunPressed = () => {
        listDispatcher({type: 'add'})
        toggleDispatcher({type: 'add'})
        openVarDispatcher({type: 'add'})
        clusterSetDispatcher({type: 'add'})
        openProfileDispatcher({type: 'add'})
        profileSetDispatcher({type: 'add'})
        openMetricsDispatcher({type: 'add'})
        metricsSetDispatcher({type: 'add'})
        clusterAlphasDispatcher({type: 'add'})
        descriptionsDispatcher({type: 'add'})
        clusterNumDispatcher({type:'add'})
    }

    const deleteRunPressed = (index) => {
        listDispatcher({type: 'remove', index: index})
        toggleDispatcher({type: 'remove', index: index})
        openVarDispatcher({type: 'remove', index: index})
        clusterSetDispatcher({type: 'remove', index: index})
        openProfileDispatcher({type: 'remove', index: index})
        profileSetDispatcher({type: 'remove', index: index})
        openMetricsDispatcher({type: 'remove', index: index})
        metricsSetDispatcher({type: 'remove', index: index})
        clusterAlphasDispatcher({type: 'remove', index: index})
        descriptionsDispatcher({type: 'remove', index: index})
        clusterNumDispatcher({type: 'remove', index: index})
    }

    const duplicatePressed = (index) => {
        listDispatcher({type: 'duplicate', index: index})
        toggleDispatcher({type: 'add'})
        openVarDispatcher({type: 'add'})
        clusterSetDispatcher({type: 'duplicate', index: index})
        openProfileDispatcher({type: 'add'})
        profileSetDispatcher({type: 'duplicate', index: index})
        openMetricsDispatcher({type: 'add'})
        metricsSetDispatcher({type: 'duplicate', index: index})
        clusterAlphasDispatcher({type: 'duplicate', index: index})
        descriptionsDispatcher({type: 'duplicate', index: index})
        clusterNumDispatcher({type: 'duplicate', index: index})
    }

    const runPing = async (jobID) => {
        await axios.get(paths.apiStatusPing + jobID + '/').then(async (response) => {
            if (response.data.status !== 'finished') {
                await new Promise(resolve => setTimeout(resolve, 2000)); // ping every 1 second
                await runPing(jobID)
            }
        })
    }

    // handles submission of the list of runs
    const submitRuns = async () => {
        await setComputeRun(true);
        let errors = []
        let promises = []

        for (var i = 0; i < runList.length; i++) {
            promises.push(axios.post(paths.apiProjectNewRun + projectID + '/', qs.stringify({
                name: runList[i],
                max_clusters: clusterNum[i],
                cluster: JSON.stringify(clusterSet[i]),
                profile: JSON.stringify(profileSet[i]),
                metrics: JSON.stringify(metricsSet[i]),
                description: descriptions[i],
                alpha: clusterAlphas[i],
            })).then(async (response) => {
                await runPing(response.data.job_id)
                setOnNumber(onNumber+1)
            }, (error) => {
                errors.push(`${error.response.data.name}: ${error.response.data.error_message}`)
            }))
        }
        await Promise.all(promises)
        await setInternalError(errors.toString())

        // redirect to the list
        if (errors.length === 0) {
            await setOnNumber(0)
            await setComputeRun(false);
            await setTabValue(1);
        }
    }

    // when loading, if the duplicate button was pressed, then load the information
    useEffect(() => {
        // fetch the run data here
        async function uploadFunction() {
            if (duplicatedRun !== '') {
                addNewRunPressed()
                const duplicateData = JSON.parse(duplicatedRun)
                listDispatcher({type: 'update', index: 0, value: duplicateData.name})
                clusterNumDispatcher({type: 'update', index: 0, value: duplicateData.clusterNum})
                clusterSetDispatcher({type: 'update', index: 0, value: duplicateData.cluster})
                profileSetDispatcher({type: 'update', index: 0, value: duplicateData.profile})
                metricsSetDispatcher({type: 'update', index: 0, value: duplicateData.metrics})
                descriptionsDispatcher({type: 'update', index: 0, value: duplicateData.description})
                clusterAlphasDispatcher({type: 'update', index: 0, value: duplicateData.alpha})
            }
        }
        uploadFunction()
    }, [duplicatedRun])

    return (
        <Box className={classes.tabContent}>
            {runList.map((entry, index) =>
                <Box
                    key={'run_'+index}
                    className={classes.runBoxMainRoot}>
                    <Box
                        classes={{root: classes.runBoxSummary}}>
                        <Box className={classes.runBoxFlex}>
                            <TextField
                                variant='outlined'
                                label='Run Name'
                                required
                                value={runList[index]}
                                onChange={(e) => listDispatcher({type: 'update', index: index, value:e.target.value})}
                                classes={{root: classes.runInput}}
                            />
                        </Box>
                        <Box className={classes.runBoxNoFlex}>
                            <TextField
                                variant='outlined'
                                label='Max # Clusters'
                                type='number'
                                error={clusterNum[index] < 0}
                                value={clusterNum[index]}
                                onChange={(e) => clusterNumDispatcher({type: 'update', index: index, value: e.target.value})}
                                required
                            />
                        </Box>
                        <Box className={classes.runBoxNoFlex}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.submitButtonWrapper}
                                onClick={() => openVarDispatcher({type: 'update', index: index})}>
                                    { clusterSet[index].length > 0 ?
                                        'Selected ' + clusterSet[index].length + ' Cluster Variables' : 'Select Cluster Variables'
                                    }
                            </Button>
                        </Box>
                        <Box className={classes.runBoxNoFlex}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.submitButtonWrapper}
                                onClick={() => openMetricsDispatcher({type: 'update', index: index})}>
                                    { metricsSet[index].length > 0 ?
                                        'Selected ' + metricsSet[index].length + ' Metrics Variables' : 'Select Metrics Variables'
                                    }
                            </Button>
                        </Box>
                        <Box className={classes.runBoxNoFlex}>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.submitButtonWrapper}
                                onClick={() => openProfileDispatcher({type: 'update', index: index})}>
                                    { clusterSet[index].length + profileSet[index].length > 0 ?
                                        (clusterSet[index].length + profileSet[index].length) + ' Total Profile Variables' : 'Select Profile Variables'
                                    }
                            </Button>
                        </Box>
                        <Box className={classes.runBoxNoFlex}>
                            <IconButton
                                className={clsx({[classes.expandIconButton]: toggleList[index]})}
                                onClick={() => toggleDispatcher({type: 'toggle', index: index})}>
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>
                    </Box>

                    <Collapse in={toggleList[index]}>
                        <Box className={classes.runBoxDetail}>
                            <Typography className={classes.runBoxDetailHeading}>
                                Advanced Run Settings
                            </Typography>
                            <Box className={classes.runBoxDetailRow}>
                                <Box className={classes.runBoxFlex}>
                                    <TextField
                                        variant='outlined'
                                        label='Run Description'
                                        fullWidth
                                        multiline
                                        rows={2}
                                        rowsMax={3}
                                        value={descriptions[index]}
                                        onChange={(e) => descriptionsDispatcher({type: 'update', index: index, value: e.target.value})}
                                    />
                                </Box>
                            </Box>
                            <Box className={classes.runBoxDetailRow}>
                                <Box className={classes.runBoxFlex}>
                                    <TextField
                                        variant='outlined'
                                        label='Clustering Alpha'
                                        fullWidth
                                        helperText='Only adjust if absolutely needed!'
                                        type='number'
                                        error={clusterAlphas[index] < 0}
                                        value={clusterAlphas[index]}
                                        onChange={(e) => clusterAlphasDispatcher({type: 'update', index: index, value: e.target.value})}
                                    />
                                </Box>
                                <Box className={classes.runBoxFlexRightFlush}>
                                    <IconButton
                                        className={classes.advancedIconButton}
                                        onClick={() => duplicatePressed(index)}>
                                        <FileCopyOutlinedIcon />
                                    </IconButton>
                                    <IconButton
                                        className={classes.advancedIconButton}
                                        onClick={() => deleteRunPressed(index)}>
                                        <DeleteOutline />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                    
                    <VariableModal 
                        open={openVar[index]}
                        handleClose={() => openVarDispatcher({type: 'update', index: index})}
                        variableDict={clusterDict}
                        variableHeaders={variableHeaders}
                        type='cluster'

                        runIndex={index}
                        selectedList={clusterSet[index]}
                        handleSelectedList={clusterSetDispatcher}
                    />
                    <VariableModal 
                        open={openMetrics[index]}
                        handleClose={() => openMetricsDispatcher({type: 'update', index: index})}
                        variableDict={metricsDict}
                        variableHeaders={variableHeaders}
                        type='metrics'

                        runIndex={index}
                        selectedList={metricsSet[index]}
                        handleSelectedList={metricsSetDispatcher}
                    />
                    <VariableModal 
                        open={openProfile[index]}
                        handleClose={() => openProfileDispatcher({type: 'update', index: index})}
                        variableDict={profileDict}
                        variableHeaders={variableHeaders}
                        type='profile'

                        runIndex={index}
                        selectedList={profileSet[index]}
                        handleSelectedList={profileSetDispatcher}

                        disableSet={clusterSet[index]}
                    />
                </Box>
            )}
            <Button
                variant='text'
                classes={{
                    text: classes.addRunButton
                }}
                color="primary"
                onClick={addNewRunPressed}>+ Add a new run</Button>
            {runList.length > 0 &&
                <Button
                    variant='contained'
                    color='primary'
                    className={classes.submitButtonWrapper}
                    classes={{contained: classes.containedSubmitButton}}
                    onClick={() => submitRuns()}>Submit</Button>
            }
            <ComputeModal open={computeRun} totalNumber={runList.length} onNumber={onNumber} error={internalError}/>
        </Box>
    )
}