/*
    Contains the modal for the About button on the header
*/

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Dialog,
        DialogContent,
        DialogContentText,
        IconButton,
        Typography,
        Grow,} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    }
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function MainHeaderAbout(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { open, handleClose } = props;

    return (
        <Dialog
            open = {open}
            onClose = {handleClose}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <DialogTitle classes={classes} id="alert-about-title" onClose = {handleClose}>
                {"About Rosemark's QP Tool"}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-about-description">
                    Welcome to Rosemark's QP Tool! Here, you can analyze your data using our clustering and typing tools.<br/>
                </DialogContentText>
                <DialogContentText id="alert-about-description2">
                    For any questions/issues regarding the site, please contact the administrator.
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

