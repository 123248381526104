/*
    Modal for changing the password, offered only at the home level
*/

import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import qs from 'qs';

import {paths} from '../../PathContext';

import Loading from '../Loading';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    fieldInput: {
        marginTop: 10,
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        marginTop: 5,
    },
    submitButtonWrapper: {
        width: '50%',
        marginTop: 10,
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    submitFontSize: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 16
    },
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function ChangePassword(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { open, handleClose } = props;
    const [ errorLength, setErrorLength ] = useState(false)
    const [ errorMatch, setErrorMatch ] = useState(false)
    const [ errorAlphaNum, setErrorAlphaNum ] = useState(false)

    const [ errorServer, setErrorServer ] = useState(false)
    const [ success, setSuccess ] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        var alphaNum = false
        var match = false
        var length = false

        const pw1 = e.target.password1.value
        const pw2 = e.target.password2.value

        match = (pw1 !== pw2)
        length = (pw1.length < 8 || pw2.length < 8)
        alphaNum = (/^\d*$/.test(pw1) || /^\d*$/.test(pw2))

        setErrorMatch(match);
        setErrorLength(length);
        setErrorAlphaNum(alphaNum);

        if (!match && !length && !alphaNum) {
            setLoading(true)
            await axios.post(paths.apiChangePW, qs.stringify({
                password1: pw1,
                password2: pw2,
            })).then((response) => {
                setSuccess(true);
            }, (error) => {
                setErrorServer(true)
            })
            setLoading(false)
        }
    }

    const closeModalReset = () => {
        handleClose()
        setSuccess(false)
        setErrorMatch(false)
        setErrorLength(false)
        setErrorAlphaNum(false)
        setErrorServer(false)
    }

    return (
        <Dialog
            open = {open}
            onClose = {closeModalReset}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <Loading open={loading}/>
            <DialogTitle classes={classes} id="alert-about-title" onClose = {closeModalReset}>
                {"Change Password"}
            </DialogTitle>
            <DialogContent dividers>
                { !success &&
                <React.Fragment>
                <DialogContentText id="alert-criteria-description">
                    Please make sure the new password satisfies the following criteria:
                </DialogContentText>
                <DialogContentText id="alert-criteria-description2">
                    1. Must be at least 8 characters
                </DialogContentText>
                <DialogContentText id="alert-criteria-description3">
                    2. Must have both numbers and letters
                </DialogContentText>
                <form onSubmit={handleSubmit}>
                    <Container className={classes.fieldView}>    
                        <TextField
                            label='Enter new password'
                            variant='outlined'
                            type='password'
                            name='password1'
                            fullWidth
                            className={classes.fieldInput}/>
                        <TextField
                            label='Re-enter new password'
                            variant='outlined'
                            type='password'
                            name='password2'
                            fullWidth
                            className={classes.fieldInput}/>
                        { errorMatch && <Typography className={classes.errorText}>Passwords do not match</Typography>}
                        { errorLength && <Typography className={classes.errorText}>Password too short</Typography>}
                        { errorAlphaNum && <Typography className={classes.errorText}>Passwords does not contain both numbers and letters</Typography>}
                        { errorServer && <Typography className={classes.errorText}>There was an internal server error. Contact the admin.</Typography>}
                        <Typography></Typography>
                        <Button
                            variant='contained'
                            color='primary'
                            classes={{contained: classes.submitButton}}
                            className={classes.submitButtonWrapper}
                            type='submit'>
                            <Typography className={classes.submitFontSize}>Change Password</Typography>
                        </Button>
                    </Container>
                </form>
                </React.Fragment>
                }
                { success &&
                <DialogContentText id="alert-success-description">
                    You have successfully changed your password! You can close this modal and continue with the QP Tool.
                </DialogContentText>
                }
            </DialogContent>
        </Dialog>
    );
};

