/*
    General home page and combines the header (MainHeader) and the body (HomeContent)
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Slide,
} from '@material-ui/core';
import MainHeader from './subcomponents/MainHeader';
import HomeContent from './subcomponents/home-level/HomeContent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minWidth: 1000,
        height: '100%',
    },
}))

export default function Home(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <MainHeader />
            <Slide direction='right' in = {true} mountOnEnter unmountOnExit>
            <Box>
                <HomeContent />
            </Box>
            </Slide>
        </Box>
    );
}