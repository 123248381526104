/*
    Typing tool tab for the run.
    Uses the RunDetailVariableModal and RunDetailSegmentModal
    to select variables and segments to run typing tool functions
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import InfoIcon from '@material-ui/icons/Info';

import RunDetailVariableModal from './RunDetailVariableModal';
import RunDetailSegmentModal from './RunDetailSegmentModal';
import {paths} from '../../PathContext';

import axios from 'axios';
import clsx from 'clsx';
import qs from 'qs';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = theme => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 15,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    runName: {
        fontSize: 30,
        fontWeight: 800,
        marginLeft: 50,
        marginRight: 20,
    },
    runDescription: {
        fontSize: 25,
        fontWeight: 400,
        width: '100%',
        marginLeft: 50,
        marginRight: 50,
    },
    fileText: {
        fontSize: 20,
        marginLeft: 10,
    },
    divider: {
        width: '90%',
        marginTop: 50,
    },

    contentRow: {
        width: '90%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 20,
    },
    contentRowRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
    },
    formBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    singleSelectCentered: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    singleSelect: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    fileInput: {
        display: 'none',
    },

    button: {
        textTransform: 'none',
        fontSize: 16,
        padding: '10px 30px',
        minWidth: 180
    },
    darkButton: {
        backgroundColor: theme.palette.dark.main,
        '&:hover': {
            backgroundColor: theme.palette.dark.light
        },
        minWidth: 200,
    },
    secondaryButton: {
        color: 'white',
        fontSize: 16,
        textTransform: 'none',
        padding: '10px 30px 10px 30px',
    },
    submitButton: {
        marginTop: 20,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    textMetrics: {
        fontSize: 20,
    },

    tableWrap: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
    },
    tableTitle: {
        fontSize: 24,
        marginTop: 20,
    },
    tableHeight: {
        maxHeight: '85vh',
        overflowX: 'auto',
        marginBottom: 40,
    },
    table: {
    },
    moveHeadInside: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    halfBoxStart: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    halfBoxEnd: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    headCellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        maxWidth: '10vw',
        minWidth: '7vw',
        fontSize: 16,
    },
    cellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: '10vw',
        minWidth: '7vw',
        fontSize: 16,
    },

    errorMessage: {
        color: 'red',
        fontSize: 14,
    },
})

class RunDetailTypingToolComponent extends React.Component {
    constructor(props) {
        super()

        this.state = {
            topResult: false, // check to see if already submitted
            result: {},
            numFeatures: '',
            selectedVariables: [],
            selectedSegments: [],
            openVariables: false,
            openSegments: false,

            dataName: '',
            dataFile: {},
            selectedVariablesPred: [],
            openVariablesPred: false,

            topkError: '', // any errors in top k algorithm
            predError: '', // any errors in predict algorithm
        }

        this.setNumFeatures = this.setNumFeatures.bind(this);
        this.submitTopK = this.submitTopK.bind(this);
        this.submitPrediction = this.submitPrediction.bind(this);
        this.resetTopK = this.resetTopK.bind(this);
        this.downloadTopKCSV = this.downloadTopKCSV.bind(this);
        this.resetFileInput = this.resetFileInput.bind(this);
        this.downloadTopKClassifier = this.downloadTopKClassifier.bind(this);
        this.downloadTopKClassifyFile = this.downloadTopKClassifyFile.bind(this);
        this.pingPrediction = this.pingPrediction.bind(this);
        this.pingTopK = this.pingTopK.bind(this);
        this.pingClassifier = this.pingClassifier.bind(this);
    }

    async componentDidMount() {
        this.props.setLoading(false)
    }

    setNumFeatures = (e) => {
        this.setState({
            numFeatures: e.target.value,
            topkError: ''
        })
    }
    setSelectedVariables = (vars) => { this.setState({selectedVariables: vars, topkError: ''}) }
    setSelectedSegments = (segs) => { this.setState({selectedSegments: segs, topkError: ''}) }
    setSelectedVariablesPred = (vars) => { this.setState({selectedVariablesPred: vars, predError: ''}) }

    handleFileChange = async (e) => { 
        const filepath = e.target.value.split('\\');
        await this.setState({
            dataName: filepath[filepath.length-1],
            dataFile: e.target.files[0],
            predError: '',
        });
    }

    // reset the inputs to the top k once it is reset
    resetTopK = () => {
        this.setState({
            numFeatures: '',
            selectedVariables: [],
            selectedSegments: [],
            topResult: false
        })
    }

    // pings the top k call constantly
    pingTopK = async (jobID) => {
        await axios.get(paths.apiResultPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'finished') {
                this.setState({
                    topResult: true,
                    result: response.data.result
                })
            }
            else {
                await new Promise(resolve => setTimeout(resolve, 1000)); // ping every 1 second
                await this.pingTopK(jobID)
            }
        })
    }

    // the logic for when submitting the specified top k features
    submitTopK = async () => {
        let error = '';
        if (this.state.numFeatures < 0) {
            error = 'Cannot have negative variable number.'
        }
        else if (this.state.numFeatures > this.state.selectedVariables.length) {
            error = 'Specified number must be at most the number of selected cluster variables.'
        }
        else {
            error = ''
        }

        this.setState({ topkError: error })

        if (error === '') {
            this.props.setLoading(true);
            await axios.post(paths.apiRunTypingToolTopK+this.props.runID+'/', qs.stringify({
                variables: JSON.stringify(this.state.selectedVariables),
                segments: JSON.stringify(this.state.selectedSegments.slice().sort()),
                feature_num: this.state.numFeatures
            })).then(async (response) => {
                await this.pingTopK(response.data.job_id)
            }, (error) => {
                this.setState({ topkError: error.response.data.error_message })
            })
            this.props.setLoading(false);
        }
    }

    // once the file is reset (aka when you hit download, allow input to receive another file again)
    resetFileInput = async () => {
        await this.setState({
            selectedVariablesPred: [],
            dataName: '',
            dataFile: {},
        });
        document.getElementById("predict-file-input").value = ""
    }

    pingPrediction = async (jobID, outputName) => {
        await axios.get(paths.apiPredFileDownloadPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'pending' || response.data.status === 'undefined') {
                await new Promise(resolve => setTimeout(resolve, 100)); // ping every 1 second
                await this.pingPrediction(jobID, outputName)
            }
            else {
                var blob = new Blob([response.data], { type: 'text/csv' }); // create a Blob using response data
                let link = document.createElement("a");
                let url = window.URL.createObjectURL(blob); 
                link.href = url // link to the blob
                link.download = outputName; // filename
                link.click() // force the click
                window.URL.revokeObjectURL(url); // release reference to file
            }
        })
    }

    // logic for predicting the outcome
    submitPrediction = async (e, pathName, outputName) => {
        e.preventDefault()

        this.props.setLoading(true);
        // make the form data with the file
        const formData = new FormData();
        formData.append('variables', JSON.stringify(this.state.selectedVariablesPred));
        formData.append('data_file', this.state.dataFile);


        await axios.post(pathName+this.props.runID+'/', formData).then((response) => {
            this.pingPrediction(response.data.job_id, outputName)
        }, (error) => {
            this.setState({predError: error.response.data.error_message})
        }).then(this.resetFileInput)
        this.props.setLoading(false);
    }


    // downloads the output of the top k algorithm, the way displayed on frontend
    downloadTopKCSV = async (e) => {
        e.preventDefault()

        this.props.setLoading(true)
        await axios.post(paths.apiRunTypingToolTopKDownloadCSV + this.props.runID + '/', qs.stringify({
            k: this.state.numFeatures,
            variables: JSON.stringify(this.state.result.features),
            stats: JSON.stringify(this.state.result.stats)
        })).then((response) => {
            // can't just set window.location.href since this is a POST request
            var blob = new Blob([response.data], { type: 'text/csv' }); // create a Blob using response data
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(blob); 
            link.href = url // link to the blob
            link.download = 'typing_tool_top_k.csv'; // filename
            link.click() // force the click
            window.URL.revokeObjectURL(url); // release reference to file

        }, (error) => {
            console.log(error.response.data.error_message) // this should never hit theoretically
        })
        this.props.setLoading(false)
    }

    /*
    The next 3 function are linked to download the classifiers
    */
    pingClassifier = async (jobID, outputName) => {
        await axios.get(paths.apiClassifierPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'pending' || response.data.status === 'undefined') {
                await new Promise(resolve => setTimeout(resolve, 100)); // ping every 1 second
                await this.pingClassifier(jobID, outputName)
            }
            else {
                var blob = new Blob([response.data], { type: 'application/json' }); // create a Blob using response data
                let link = document.createElement("a");
                let url = window.URL.createObjectURL(blob); 
                link.href = url // link to the blob
                link.download = outputName; // filename
                link.click() // force the click
                window.URL.revokeObjectURL(url); // release reference to file
            }
        })
    }

    // get the classifer WITHOUT the probabilities
    downloadTopKClassifier = async (e, pathName, outputName) => {
        e.preventDefault()

        this.props.setLoading(true)
        await axios.post(pathName + this.props.runID + '/', qs.stringify({
            variables: JSON.stringify(this.state.result.feature_arr),
            segments: JSON.stringify(this.state.selectedSegments.slice().sort())
        })).then(async (response) => {
            await this.pingClassifier(response.data.job_id, outputName)
        }, (error) => {
            console.log(error.response.data.error_message) // this should never hit theoretically
        })
        this.props.setLoading(false)
    }

    // get the classify python distribute file
    downloadTopKClassifyFile = async (e) => {
        this.props.setLoading(true)
        await axios.get(paths.apiRunTypingToolTopKDownloadClassify + this.props.runID + '/').then((response) => {
            // can't just set window.location.href since this is a POST request
            var blob = new Blob([response.data], { type: 'text/x-python' }); // create a Blob using response data
            let link = document.createElement("a");
            let url = window.URL.createObjectURL(blob); 
            link.href = url // link to the blob
            link.download = 'classify_csv.py'; // filename
            link.click() // force the click
            window.URL.revokeObjectURL(url); // release reference to file

        }, (error) => {
            console.log(error.response.data.error_message) // this should never hit theoretically
        })
        this.props.setLoading(false)
    }

    render() {
    const {
        classes,
    } = this.props

    return (
        <React.Fragment>
            <Box className={classes.row}>
                <Typography className={classes.runName}>{`${this.props.name} Typing Tool`}</Typography>
            </Box>
            <Box className={classes.row}>
                <Typography className={classes.runDescription}>{`Top K Variables`}</Typography>
            </Box>
            { this.state.topResult ?
                <Box className={classes.contentRow}>
                    <Box className={classes.formBox}>
                        <Box className={classes.contentRowRow}>
                            <Box className={classes.singleSelect}>
                                <Typography className={classes.textMetrics}>{`Top ${this.state.numFeatures} Variables: ${this.state.result.features}`}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.contentRowRow}>
                            <Box className={classes.singleSelect}>
                                <Typography className={classes.textMetrics}>{`acc: ${this.state.result.stats.acc}`}</Typography>
                            </Box>
                            <Box className={classes.singleSelect}>
                                <Typography className={classes.textMetrics}>{`acc_targets_and_out: ${this.state.result.stats.acc_targets_and_out}`}</Typography>
                            </Box>
                            <Box className={classes.singleSelect}>
                                <Typography className={classes.textMetrics}>{`acc_in_target_groups: ${this.state.result.stats.acc_in_target_groups}`}</Typography>
                            </Box>
                        </Box>
                        <Toolbar>
                            <Typography className={classes.tableTitle}>{`Contingency Table (Row Aggregate)`}</Typography>
                        </Toolbar>
                        <TableContainer component={Paper} classes={{root: classes.tableHeight}}>
                            <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={{head: classes.headCellStyle}}>{''}</TableCell>
                                        { this.state.result.stats.contingency_headers.slice(0, this.state.result.stats.contingency_headers.length-1)
                                            .map((val, index) => (
                                           <TableCell key={`header-${index}`} className={classes.headCellStyle}>
                                                <Box className={classes.moveHeadInside}>
                                                    <Box className={classes.halfBoxStart}>
                                                        {val}
                                                    </Box>
                                                    <Box className={classes.halfBoxEnd}>
                                                        <Tooltip title={this.props.headerDescriptions[index]}>
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                           </TableCell>
                                        ))}
                                        <TableCell classes={{head: classes.headCellStyle}}>
                                            {this.state.result.stats.contingency_headers[this.state.result.stats.contingency_headers.length-1]}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.state.result.stats.contingency.map((row, index) => (
                                        <TableRow>
                                            { row.map((val, index2) => (
                                                <TableCell key={`cell-${index}-${index2}`}
                                                className={clsx({[classes.cellStyle]: index2 > 0, [classes.headCellStyle]: index2 === 0})}>
                                                    {val}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Toolbar>
                            <Typography className={classes.tableTitle}>{`Contingency Table (Column Aggregate)`}</Typography>
                        </Toolbar>
                        <TableContainer component={Paper} classes={{root: classes.tableHeight}}>
                            <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell classes={{head: classes.headCellStyle}}>{''}</TableCell>
                                        { this.state.result.stats.contingency_headers.slice(0, this.state.result.stats.contingency_headers.length-1)
                                            .map((val, index) => (
                                           <TableCell key={`header-${index}`} className={classes.headCellStyle}>
                                                <Box className={classes.moveHeadInside}>
                                                    <Box className={classes.halfBoxStart}>
                                                        {val}
                                                    </Box>
                                                    <Box className={classes.halfBoxEnd}>
                                                        <Tooltip title={this.props.headerDescriptions[index]}>
                                                            <InfoIcon />
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                           </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { this.state.result.stats.contingency_col.map((row, index) => (
                                        <TableRow>
                                            { row.map((val, index2) => (
                                                <TableCell key={`cell-${index}-${index2}`}
                                                className={clsx({[classes.cellStyle]: index2 > 0, [classes.headCellStyle]: index2 === 0})}>
                                                    {val}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box className={classes.contentRowRow}>
                            <Box className={classes.singleSelectCentered}>
                                <Button
                                    variant='contained' color='primary'
                                    className={clsx({[classes.darkButton]: true, [classes.button]: true, [classes.submitButton]: true})}
                                    onClick={this.resetTopK}>Reset</Button>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    className={clsx({[classes.secondaryButton]: true, [classes.submitButton]: true})}
                                    onClick={this.downloadTopKCSV}>
                                    <GetAppRoundedIcon/><Typography>output .csv</Typography>
                                </Button>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    className={clsx({[classes.secondaryButton]: true, [classes.submitButton]: true})}
                                    onClick={(e) => this.downloadTopKClassifier(e, paths.apiRunTypingToolTopKDownloadClassifier, 'classifier_simple.json')}>
                                    <GetAppRoundedIcon/><Typography>classifier seg. only</Typography>
                                </Button>
                                <Tooltip title='Warning: This operation will be slow (est. 5-15+ minutes)'>
                                    <Button
                                        variant='contained'
                                        color='secondary'
                                        className={clsx({[classes.secondaryButton]: true, [classes.submitButton]: true})}
                                        onClick={(e) => this.downloadTopKClassifier(e, paths.apiRunTypingToolTopKDownloadClassifierProbability, 'classifier_probability.json')}>
                                        <GetAppRoundedIcon/><Typography>classifier w/ prob.</Typography>
                                    </Button>
                                </Tooltip>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    className={clsx({[classes.secondaryButton]: true, [classes.submitButton]: true})}
                                    onClick={this.downloadTopKClassifyFile}>
                                    <GetAppRoundedIcon/><Typography>classify_csv.py</Typography>
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                    :
                
                <Box className={classes.contentRow}>
                    <Box className={classes.formBox}>
                        <Box className={classes.contentRowRow}>
                            <Box className={classes.singleSelectCentered}>
                                <TextField
                                    label='Num. of Variables'
                                    type='number'
                                    variant='outlined'
                                    value={this.state.numFeatures}
                                    onChange={this.setNumFeatures}
                                />
                            </Box>
                            <Box className={classes.singleSelectCentered}>
                                <Button
                                    variant='contained' color='primary'
                                    className={clsx({[classes.darkButton]: true, [classes.button]: true})}
                                    onClick={() => {this.setState({openVariables: true})}}>
                                    { this.state.selectedVariables.length > 0 ?
                                        'Selected ' + this.state.selectedVariables.length + ' Variables' : 'Select Variables'
                                    }
                                </Button>
                            </Box>
                            <Box className={classes.singleSelectCentered}>
                                <Button
                                    variant='contained' color='primary'
                                    className={clsx({[classes.darkButton]: true, [classes.button]: true})}
                                    onClick={() => {this.setState({openSegments: true})}}>
                                    { this.state.selectedSegments.length > 0 ?
                                        'Selected ' + this.state.selectedSegments.length + ' Segments' : 'Select Segments'
                                    }
                                </Button>
                            </Box>
                        </Box>
                        <Box className={classes.contentRowRow}>
                            <Box className={classes.singleSelectCentered}>
                                <Typography className={classes.errorMessage}>{this.state.topkError}</Typography>
                            </Box>
                        </Box>
                        <Box className={classes.contentRowRow}>
                            <Box className={classes.singleSelectCentered}>
                                { this.state.numFeatures !== '' && this.state.selectedVariables.length > 0 && this.state.selectedSegments.length > 0 &&
                                    <Button
                                        variant='contained' color='primary'
                                        className={clsx({[classes.darkButton]: true, [classes.button]: true, [classes.submitButton]: true})}
                                        disabled={this.state.topkError !== ''}
                                        onClick={this.submitTopK}>Submit</Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                    <RunDetailVariableModal
                        open={this.state.openVariables}
                        handleClose={() => {this.setState({openVariables: false})}}
                        variableDict={this.props.variableDict}
                        variableHeaders={this.props.variableHeaders}
                        type='topk'
                        selectedList={this.state.selectedVariables}
                        handleSelectedList={this.setSelectedVariables}
                    />
                    <RunDetailSegmentModal
                        open={this.state.openSegments}
                        handleClose={() => {this.setState({openSegments: false})}}
                        segmentList={this.props.columnOrder}
                        segmentHeaders={['Segment Name']}
                        selectedList={this.state.selectedSegments}
                        handleSelectedList={this.setSelectedSegments}
                    />
                </Box>
            }

            <Divider variant='middle' className={classes.divider} />

            <Box className={classes.row}>
                <Typography className={classes.runDescription}>{`Segmentation Prediction`}</Typography>
            </Box>
            <Box className={classes.contentRow}>
                <Box className={classes.formBox}>
                    <Box className={classes.contentRowRow}>
                        <Box className={classes.singleSelectCentered}>
                            <input type='file' name='file' className={classes.fileInput} id='predict-file-input' accept='.csv' onChange={this.handleFileChange}/>
                            <label htmlFor='predict-file-input'>
                                <Button variant='outlined' color='primary' component='span' className={classes.button}>Upload Data</Button>
                            </label>
                            <Typography className={classes.fileText}>{this.state.dataName}</Typography>
                        </Box>
                        <Box className={classes.singleSelectCentered}>
                            <Button
                                variant='contained' color='primary'
                                className={clsx({[classes.darkButton]: true, [classes.button]: true})}
                                onClick={() => {this.setState({openVariablesPred: true})}}>
                                { this.state.selectedVariablesPred.length > 0 ?
                                    'Selected ' + this.state.selectedVariablesPred.length + ' Variables' : 'Select Variables'
                                }
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.contentRowRow}>
                        <Box className={classes.singleSelectCentered}>
                            { this.state.dataName !== '' && this.state.selectedVariablesPred.length > 0 &&
                                <React.Fragment>
                                    <Button
                                        variant='contained' color='primary'
                                        name='segonly'
                                        className={clsx({[classes.darkButton]: true, [classes.button]: true, [classes.submitButton]: true})}
                                        onClick={(e) => this.submitPrediction(e, paths.apiRunTypingToolPredict, 'seg_assignments.csv')}>
                                            Predict seg. only</Button>
                                    <Tooltip title='Warning: This operation will be slow (est. 5-15+ minutes)'>
                                    <Button
                                        variant='contained' color='primary'
                                        name='wprob'
                                        className={clsx({[classes.darkButton]: true, [classes.button]: true, [classes.submitButton]: true})}
                                        onClick={(e) => this.submitPrediction(e, paths.apiRunTypingToolPredictProbability, 'seg_assignments_wprob.csv')}>
                                            Predict w/ prob.</Button>
                                    </Tooltip>
                                </React.Fragment>
                            }
                        </Box>
                    </Box>
                </Box>
                <RunDetailVariableModal
                    open={this.state.openVariablesPred}
                    handleClose={() => {this.setState({openVariablesPred: false})}}
                    variableDict={this.props.variableDict}
                    variableHeaders={this.props.variableHeaders}
                    type='pred'
                    selectedList={this.state.selectedVariablesPred}
                    handleSelectedList={this.setSelectedVariablesPred}
                />
            </Box>
        </React.Fragment>
    )
}}

export default withStyles(useStyles)(RunDetailTypingToolComponent)