import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { Link } from "@reach/router"
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import qs from 'qs'
import { paths } from '../../PathContext';
import Loading from '../Loading';
import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    // for the register/login section
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        marginBottom: 20,
    },
    subheading: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10
    },
    subheadingtitle: {
        fontSize: 20,
        fontFmaily: 'Source Sans Pro',
        marginTop: 'auto',
        marginBottom: 10,
        marginLeft: 10,
    },
    textInput: {
        width: '100%',
        marginTop: 10,
    },
    buttonWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 10,
        paddingLeft: 0,
        paddingRight: 0,
    },
    submitButtonWrapper: {
        width: '50%',
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    submitFontSize: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 14
    },
    errorText: {
        fontSize: 14,
        color: 'red',
        marginBottom: 10,
    }
}))

export default function PreLogResetPW(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [expired, setExpired] = useState(false)

    const [ errorLength, setErrorLength ] = useState(false)
    const [ errorMatch, setErrorMatch ] = useState(false)
    const [ errorAlphaNum, setErrorAlphaNum ] = useState(false)

    const [ errorServer, setErrorServer ] = useState(false)
    const [ success, setSuccess ] = useState(false)

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        var alphaNum = false
        var match = false
        var length = false

        const pw1 = e.target.password1.value
        const pw2 = e.target.password2.value

        match = (pw1 !== pw2)
        length = (pw1.length < 8 || pw2.length < 8)
        alphaNum = (/^\d*$/.test(pw1) || /^\d*$/.test(pw2))

        setErrorMatch(match);
        setErrorLength(length);
        setErrorAlphaNum(alphaNum);

        if (!match && !length && !alphaNum) {
            setLoading(true)
            await axios.post(paths.resetPassword + props.keyVal +'/', qs.stringify({
                password1: pw1,
                password2: pw2,
            })).then((response) => {
                setSuccess(true);
            }, (error) => {
                setErrorServer(true)
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        axios.get(paths.apiCheckKey + props.keyVal)
            .then((response) => {
                setExpired(response.data.key_expired);
        })
    })

    return (
        <React.Fragment>
            <Loading open={loading}/>
            <GoogleReCaptcha />
            <Box className={classes.subheading}>
                { (success || expired) &&
                    <Link to={paths.login}>
                        <IconButton>
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                }
                <Typography className={classes.subheadingtitle}>Reset Password</Typography>
            </Box>
            { (!success && expired) &&
                <Container className={classes.contentContainer}>
                    <Typography>This request has expired (exceeded 24 hours). Please try to reset the password again.</Typography>
                </Container>
            }
            { (success || !expired) &&
                <React.Fragment>
                    { success &&
                        <Container className={classes.contentContainer}>
                            <Typography>You have successfully changed your password.</Typography>
                        </Container>
                    }
                    { !success && 
                        <form onSubmit={handleSubmit}>
                            <Container className={classes.contentContainer}>
                                <Typography>Please make sure the new password satisfies the following criteria:</Typography>
                                <Typography>1. Must be at least 8 characters</Typography>
                                <Typography>2. Must have both numbers and letters</Typography>
                                <TextField
                                    label='Enter new password'
                                    variant='outlined'
                                    type='password'
                                    name='password1'
                                    fullWidth
                                    className={classes.textInput}/>
                                <TextField
                                    label='Re-enter new password'
                                    variant='outlined'
                                    type='password'
                                    name='password2'
                                    fullWidth
                                    className={classes.textInput}/>
                                <Container className={classes.buttonWrapper}>
                                    { errorMatch && <Typography className={classes.errorText}>Passwords do not match</Typography>}
                                    { errorLength && <Typography className={classes.errorText}>Password too short</Typography>}
                                    { errorAlphaNum && <Typography className={classes.errorText}>Passwords does not contain both numbers and letters</Typography>}
                                    { errorServer && <Typography className={classes.errorText}>There was an internal server error. Contact the admin.</Typography>}
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        classes={{contained: classes.submitButton}}
                                        className={classes.submitButtonWrapper}
                                        type='submit'>
                                        <Typography className={classes.submitFontSize}>Reset Password</Typography>
                                    </Button>
                                </Container>
                            </Container>
                        </form>
                    }
                </React.Fragment>
            }
        </React.Fragment>
    );
}