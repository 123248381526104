/*
    Forgot PW page. Combines a header (PreLogHeader) and 
    the body (PreLogForgotPW)

    PreLogForgotPW contains the component-level implementation.
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Container,
    Slide,
} from '@material-ui/core';
import Background from './images/login_background.jpg'
import PreLogHeader from './subcomponents/prelog-level/PreLogHeader'
import PreLogForgotPW from './subcomponents/prelog-level/PreLogForgotPW'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        width: '100%',
        minWidth: 1000,
        height: '100%',
        zIndex: -1,

        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    overlay: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#10101050',
    },
    loginContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 100,
        width: '25%',
        zIndex: 1,
        overflow: 'hidden',

        boxShadow: '-2px 2px 7px 2px rgba(0,0,0,0.6)',
        borderRadius: 8,
    },
}))

export default function ForgotPW(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.overlay}>
                <Container className={classes.loginContainer}>
                    <PreLogHeader/>
                    <Slide direction='left' in = {true} mountOnEnter unmountOnExit>
                        <div style={{width: '100%'}}>
                            <PreLogForgotPW/>
                        </div>
                    </Slide>
                </Container>
            </Box>
        </Box>
    );
}