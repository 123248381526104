/*
    Summary tab of the run
*/

import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import Checkbox from '@material-ui/core/Checkbox';

import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import RunUpdateHeaderDescription from './RunUpdateHeaderDescription';

import axios from 'axios';
import clsx from 'clsx';

import {paths} from '../../PathContext'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useHeaderStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        fontSize: 24,
        marginLeft: 20,
    },
    filterFlex: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        marginRight: 20,
        alignItems: 'center',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    formControlLarge: {
        margin: theme.spacing(1),
        minWidth: 170,
    },

    rightMost: {
        marginRight: 100,
    },
    sortRowsDirectionIconLeft: {
        margin: theme.spacing(1),
        marginRight: 30
    },
    sortRowsDirectionIcon: {
        margin: theme.spacing(1),
        marginRight: 70,
    },
    descending: {
        transform: 'rotate(180deg)',
    },
}))

function CrossTabHeader(props) {
    const classes = useHeaderStyles();

    const toggleSortRowsDir = () => {
        if (props.sortRowsDir === 'asc') {
            props.setSortRowsDir('desc')
        }
        else {
            props.setSortRowsDir('asc')
        }
    }
    const toggleSortColsDir = () => {
        if (props.sortColsDir === 'asc') {
            props.setSortColsDir('desc')
        }
        else {
            props.setSortColsDir('asc')
        }
    }

    return (
        <Toolbar className={classes.root}>
            <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
                {props.name} Summary
            </Typography>

            <Box className={classes.filterFlex}>
                <TextField
                    className={clsx({[classes.formControlLarge]: true})}
                    label='Sort Columns By'
                    value={props.sortColsBy}
                    onChange={(e) => props.setSortColsBy(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    { props.columnSortData.map((row, index) => (
                        <MenuItem key={`col-select-${index}`} value={index}>{row.name}</MenuItem>
                    ))}
                </TextField>
                {props.sortColsBy !== '' &&
                    <Tooltip title={props.sortColsDir === 'asc' ? 'Ascending' : 'Descending'}>
                            <ArrowUpIcon className={clsx({[classes.sortRowsDirectionIconLeft]: true, [classes.descending]: props.sortColsDir !== 'asc'})}
                            onClick={toggleSortColsDir}/>
                    </Tooltip>
                }

                <TextField
                    className={clsx({[classes.formControl]: true, [classes.rightMost]: props.sortRowsBy === ''})}
                    label='Sort Rows By'
                    value={props.sortRowsBy}
                    onChange={(e) => props.setSortRowsBy(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    <MenuItem value={'Absolute Value'}>Absolute Val.</MenuItem>
                    <MenuItem value={'Marginal Value'}>Marginal Val.</MenuItem>
                </TextField>
                {props.sortRowsBy !== '' &&
                    <Tooltip title={props.sortRowsDir === 'asc' ? 'Ascending' : 'Descending'}>
                            <ArrowUpIcon className={clsx({[classes.sortRowsDirectionIcon]: true, [classes.descending]: props.sortRowsDir !== 'asc'})}
                            onClick={toggleSortRowsDir}/>
                    </Tooltip>
                }

                <TextField
                    className={classes.formControl}
                    label='Value Criteria'
                    value={props.filterCriteriaValue}
                    onChange={(e) => props.setFilterCriteriaValue(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    <MenuItem value={'eq'}>=</MenuItem>
                    <MenuItem value={'neq'}>≠</MenuItem>
                    <MenuItem value={'l'}>{'<'}</MenuItem>
                    <MenuItem value={'g'}>{'>'}</MenuItem>
                    <MenuItem value={'le'}>≤</MenuItem>
                    <MenuItem value={'ge'}>≥</MenuItem>
                </TextField>

                <TextField
                    className={classes.formControl}
                    label='Value Threshold'
                    value={props.filterThresholdValue}
                    onChange={(e) => props.setFilterThresholdValue(e.target.value)}/>

                <TextField
                    className={classes.formControl}
                    label='Index Criteria'
                    value={props.filterCriteriaIndex}
                    onChange={(e) => props.setFilterCriteriaIndex(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    <MenuItem value={'eq'}>=</MenuItem>
                    <MenuItem value={'neq'}>≠</MenuItem>
                    <MenuItem value={'l'}>{'<'}</MenuItem>
                    <MenuItem value={'g'}>{'>'}</MenuItem>
                    <MenuItem value={'le'}>≤</MenuItem>
                    <MenuItem value={'ge'}>≥</MenuItem>
                </TextField>

                <TextField
                    className={classes.formControl}
                    label='Index Threshold'
                    value={props.filterThresholdIndex}
                    onChange={(e) => props.setfilterThresholdIndex(e.target.value)}/>
            </Box>
        </Toolbar>
    )
}

function AMVHeader(props) {
    const classes = useHeaderStyles();

    const toggleSortRowsDir = () => {
        if (props.sortRowsDir === 'asc') {
            props.setSortRowsDir('desc')
        }
        else {
            props.setSortRowsDir('asc')
        }
    }
    const toggleSortColsDir = () => {
        if (props.sortColsDir === 'asc') {
            props.setSortColsDir('desc')
        }
        else {
            props.setSortColsDir('asc')
        }
    }

    return (
        <Toolbar className={classes.root}>
            <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
                Absolute and Marginal Values
            </Typography>

            <Box className={classes.filterFlex}>
                <TextField
                    className={clsx({[classes.formControlLarge]: true})}
                    label='Sort Columns By'
                    value={props.sortColsBy}
                    onChange={(e) => props.setSortColsBy(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    { props.columnSortData.map((row, index) => (
                        <MenuItem key={`col-select-${index}`} value={index}>{row.name}</MenuItem>
                    ))}
                </TextField>
                {props.sortColsBy !== '' &&
                    <Tooltip title={props.sortColsDir === 'asc' ? 'Ascending' : 'Descending'}>
                            <ArrowUpIcon className={clsx({[classes.sortRowsDirectionIconLeft]: true, [classes.descending]: props.sortColsDir !== 'asc'})}
                            onClick={toggleSortColsDir}/>
                    </Tooltip>
                }

                <TextField
                    className={clsx({[classes.formControl]: true, [classes.rightMost]: props.sortRowsBy === ''})}
                    label='Sort Rows By'
                    value={props.sortRowsBy}
                    onChange={(e) => props.setSortRowsBy(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    <MenuItem value={'Absolute Value'}>Absolute Val.</MenuItem>
                    <MenuItem value={'Marginal Value'}>Marginal Val.</MenuItem>
                </TextField>
                {props.sortRowsBy !== '' &&
                    <Tooltip title={props.sortRowsDir === 'asc' ? 'Ascending' : 'Descending'}>
                            <ArrowUpIcon className={clsx({[classes.sortRowsDirectionIcon]: true, [classes.descending]: props.sortRowsDir !== 'asc'})}
                            onClick={toggleSortRowsDir}/>
                    </Tooltip>
                }

                <TextField
                    className={classes.formControl}
                    label='Value Criteria'
                    value={props.filterCriteriaValue}
                    onChange={(e) => props.setFilterCriteriaValue(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    <MenuItem value={'eq'}>=</MenuItem>
                    <MenuItem value={'neq'}>≠</MenuItem>
                    <MenuItem value={'l'}>{'<'}</MenuItem>
                    <MenuItem value={'g'}>{'>'}</MenuItem>
                    <MenuItem value={'le'}>≤</MenuItem>
                    <MenuItem value={'ge'}>≥</MenuItem>
                </TextField>

                <TextField
                    className={classes.formControl}
                    label='Value Threshold'
                    value={props.filterThresholdValue}
                    onChange={(e) => props.setFilterThresholdValue(e.target.value)}/>

                <TextField
                    className={classes.formControl}
                    label='Index Criteria'
                    value={props.filterCriteriaIndex}
                    onChange={(e) => props.setFilterCriteriaIndex(e.target.value)}
                    select>
                    <MenuItem value={''}>-Select one-</MenuItem>
                    <MenuItem value={'eq'}>=</MenuItem>
                    <MenuItem value={'neq'}>≠</MenuItem>
                    <MenuItem value={'l'}>{'<'}</MenuItem>
                    <MenuItem value={'g'}>{'>'}</MenuItem>
                    <MenuItem value={'le'}>≤</MenuItem>
                    <MenuItem value={'ge'}>≥</MenuItem>
                </TextField>

                <TextField
                    className={classes.formControl}
                    label='Index Threshold'
                    value={props.filterThresholdIndex}
                    onChange={(e) => props.setfilterThresholdIndex(e.target.value)}/>
            </Box>
        </Toolbar>
    )
}

function SummaryHeader(props) {
    const classes = useHeaderStyles();

    return (
        <Toolbar className={classes.root}>
            <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
                Average Segmentation Probabilities
            </Typography>
        </Toolbar>
    )
}

const useStyles = theme => ({
    tabContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 50,
    },
    runNav: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        alignItems: 'center',
        marginBottom: 20,
    },
    returnButton: {
        padding: '10px 30px 10px 30px',
        marginRight: 20,
        textTransform: 'none'
    },
    returnText: {
        marginLeft: 5,
        fontSize: 18,
    },
    tertiaryButton: {
        backgroundColor: theme.palette.tertiary.dark,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main
        },
        color: 'white',
        textTransform: 'none',
        fontSize: 16,
        padding: '10px 30px 10px 30px',
        marginLeft: 20,
        marginRight: 20,
    },
    tertiaryButtonSelected: {
        backgroundColor: theme.palette.tertiary.main
    },
    downloadBox: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    secondaryButton: {
        marginRight: theme.spacing(1),
        color: 'white',
        fontSize: 16,
        textTransform: 'none',
        padding: '10px 30px 10px 30px',
    },


    tableHeight: {
        maxHeight: '85vh',
        overflowX: 'auto',
        marginBottom: 40,
    },
    table: {
    },
    cellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: '10vw',
        minWidth: '7vw',
        fontSize: 16,
    },
    headCellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        maxWidth: '10vw',
        minWidth: '7vw',
    },
    moveHeadCellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        maxWidth: '10vw',
        minWidth: '7vw',

        '&:hover': {
            borderColor: theme.palette.secondary.main,
        }
    },
    moveHeadInside: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    halfBoxStart: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },
    halfBoxEnd: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    clickableButton: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    filteredCell: {
        backgroundColor: theme.palette.tertiary.main
    },
    borderCell: {
        borderTop: '2px solid rgba(50, 50, 50, 1)'
    },
    variableCell: {
        minWidth: 200,
    },
    profileCell: {
        backgroundColor: 'rgba(240, 240, 240, 1)'
    },
    clusterCell: {
        backgroundColor: '#4D85C520'
    },
    metricsCell: {
        backgroundColor: '#FF8F0020'
    },
    // for the checkbox custom styling
    checkboxStyler: {
        height: 21,
        padding: 0,
        paddingRight: 3,
    }

})


/*
Sort the crosstab
*/

function descendingComparator(a, b, orderBy) {
    const aVal = orderBy === 'Absolute Value' ? a.rows[0].value[a.rows[0].value.length - 1] : a.rows[1].value[a.rows[1].value.length - 1]
    const bVal = orderBy === 'Absolute Value' ? b.rows[0].value[b.rows[0].value.length - 1] : b.rows[1].value[b.rows[1].value.length - 1]

    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, orderBy) {
    if (orderBy === '') {
        return array
    }
    else if (array[0].rows[0].name !== 'Absolute Value') {
        return array
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

/*
Sort the AMV section (absolute-marginal value)
*/
function stableSortAMV(array, comparator, orderBy) {
    if (orderBy === '') {
        return array
    }
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class RunDetailSummaryComponent extends React.Component {
    constructor(props) {
        super()

        this.state = {
            probabilities: []
        }

        this.openDescriptionChange = this.openDescriptionChange.bind(this);
    }

    async componentDidMount() {
        await axios.get(paths.apiRunProbabilityLoad + this.props.runID + '/').then((response) => {
            this.setState({
                probabilities: response.data.probabilities
            })
        })
        this.props.setLoading(false)
    }

    openDescriptionChange = (index) => {
        this.setState({
            updateOrderNum: index,
            updateDescriptionOpen: true,
        })
    }

    render() {
    const {
        classes,
        name,
        generalHeaders,
        sortableHeaders,
        crosstabCluster,
        crosstabProfile,
        crosstabMetrics,
        columnOrder,
        headerDescriptions,
        filterCriteriaValue,
        filterThresholdValue,
        filterCriteriaIndex,
        filterThresholdIndex,
        sortRowsBy,
        sortRowsDir,
        sortColsBy,
        sortColsDir,
        columnSortData,

        setOrder,
        setFilterCriteriaValue,
        setFilterThresholdValue,
        setFilterCriteriaIndex,
        setFilterThresholdIndex,
        setHeaderDescriptions,
        setSortRowsBy,
        setSortRowsDir,
        setSortColsBy,
        setSortColsDir,
        runID,
        amvList,

        cluster,
        profile,
        metrics,
        setCluster,
        setProfile,
        setMetrics,
    } = this.props

    const SortableCellEditable = SortableElement(({value, title}) => 
        <TableCell key={value} className={classes.moveHeadCellStyle}>
            <Box className={classes.moveHeadInside}>
                <Box className={classes.halfBoxStart}>
                    {sortableHeaders[value]}
                </Box>
                <Box className={classes.halfBoxEnd}>
                    <Tooltip title={title}>
                        <InfoIcon />
                    </Tooltip>
                    <EditIcon
                        className={classes.clickableButton}
                        onClick={() => this.openDescriptionChange(value)}
                    />
                    
                </Box>
            </Box>
        </TableCell>);

    const SortableCell= SortableElement(({value}) => 
        <TableCell key={value} className={classes.moveHeadCellStyle}>
            {sortableHeaders[parseInt(value)-1]}
        </TableCell>);

    const SortableList = SortableContainer(({genericItems, sortItems, headerDescriptions, setHeaderDescriptions, headerLen}) => {
        return (
            <TableRow>
                {genericItems.map((header) => (
                    <TableCell key={header} className={classes.headCellStyle}>
                        {header}
                    </TableCell>
                ))}
                {sortItems.map((value, index) => (
                    parseInt(value) === headerLen ?
                        <SortableCell key={`item-${value}`}
                            index={index} value={value} />
                        :
                        <SortableCellEditable key={`item-${value}`}
                            index={index} value={parseInt(value)-1}
                            title={headerDescriptions[parseInt(value)-1]}/>
                ))}
                <RunUpdateHeaderDescription
                    open={this.state.updateDescriptionOpen}
                    handleClose={() => {this.setState({updateDescriptionOpen: false})}}
                    runID={runID}
                    headerDescriptions={headerDescriptions}
                    orderNum={this.state.updateOrderNum}
                    setHeaderDescriptions={setHeaderDescriptions}
                />
            </TableRow>
        );
    });

    const onSortEnd = ({oldIndex, newIndex}) => {
        setOrder(arrayMove(columnOrder, oldIndex, newIndex));
    };

    // check to see if it fits the filter value
    const checkFilter = (value, index, ind) => {
        let checkValue;
        let evaluationIndex = false
        let evaluationValue = false
        let EPS = 0.000001
        // filter by index AND value
        if (filterThresholdIndex !== '') {
            // check if index is not undefined
            if (index) {
                checkValue = index[ind]
                // make sure that it isn't -1, which is placeholder for empty
                if (checkValue >= 0-EPS) {
                    switch (filterCriteriaIndex) {
                        case 'eq':
                            evaluationIndex = (checkValue+0.000001) > filterThresholdIndex && (checkValue-0.000001) < filterThresholdIndex
                            break;
                        case 'neq':
                            evaluationIndex = checkValue !== filterThresholdIndex
                            break;
                        case 'l':
                            evaluationIndex = checkValue < filterThresholdIndex
                            break;
                        case 'g':
                            evaluationIndex = checkValue > filterThresholdIndex
                            break;
                        case 'le':
                            evaluationIndex = checkValue <= filterThresholdIndex
                            break;
                        case 'ge':
                            evaluationIndex = checkValue >= filterThresholdIndex
                            break;
                        default:
                            break;
                    }
                }
            }
        }

        if (filterThresholdValue !== '') {
            checkValue = value
            switch (filterCriteriaValue) {
                case 'eq':
                    evaluationValue = (checkValue+0.000001) > filterThresholdValue && (checkValue-0.000001) < filterThresholdValue
                    break;
                case 'neq':
                    evaluationValue = checkValue !== filterThresholdValue
                    break;
                case 'l':
                    evaluationValue = checkValue < filterThresholdValue
                    break;
                case 'g':
                    evaluationValue = checkValue > filterThresholdValue
                    break;
                case 'le':
                    evaluationValue = checkValue <= filterThresholdValue
                    break;
                case 'ge':
                    evaluationValue = checkValue >= filterThresholdValue
                    break;
                default:
                    break;
            }
        }

        if (filterThresholdValue !== '' && filterThresholdIndex !== '' && filterCriteriaValue !== '' && filterCriteriaIndex !== '') {
            return evaluationValue && evaluationIndex
        }
        else if (filterThresholdIndex !== '' && filterCriteriaIndex !== '') {
            return evaluationIndex
        }
        else if (filterThresholdValue !== '' && filterCriteriaValue !== '') {
            return evaluationValue
        }
        return false
    }

    const isSelectedCluster = (name) => cluster.indexOf(name) !== -1;
    const isSelectedProfile = (name) => profile.indexOf(name) !== -1;
    const isSelectedMetrics = (name) => metrics.indexOf(name) !== -1;

    const handleClick = (event, name, selected, setSelected) => {

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    return (
        <React.Fragment>
            <CrossTabHeader
                name={name}
                filterCriteriaValue={filterCriteriaValue}
                setFilterCriteriaValue={setFilterCriteriaValue}
                filterThresholdValue={filterThresholdValue}
                setFilterThresholdValue={setFilterThresholdValue}

                filterCriteriaIndex={filterCriteriaIndex}
                setFilterCriteriaIndex={setFilterCriteriaIndex}
                filterThresholdIndex={filterThresholdIndex}
                setfilterThresholdIndex={setFilterThresholdIndex}
                sortRowsBy={sortRowsBy}
                setSortRowsBy={setSortRowsBy}
                sortRowsDir={sortRowsDir}
                setSortRowsDir={setSortRowsDir}
                sortColsBy={sortColsBy}
                setSortColsBy={setSortColsBy}
                sortColsDir={sortColsDir}
                setSortColsDir={setSortColsDir}
                setOrder={setOrder}
                columnSortData={columnSortData}/>
            <TableContainer component={Paper} classes={{root: classes.tableHeight}}>
                <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <SortableList axis='x' lockAxis='x' onSortEnd={onSortEnd} distance={1}
                            genericItems={generalHeaders}
                            sortItems={columnOrder} headerLen={columnOrder.length}
                            headerDescriptions={headerDescriptions} setHeaderDescriptions={setHeaderDescriptions}/>
                    </TableHead>
                    <TableBody>
                        {crosstabMetrics.map((dimension, index) => (
                            <React.Fragment key={'dimension_frag'+index}>
                            <TableRow key={'dimension' + index}>
                                <TableCell className={clsx({[classes.cellStyle]: true, [classes.metricsCell]: true})}
                                    rowSpan={dimension.length}>{dimension.name}</TableCell>
                            </TableRow>
                            {dimension.factors.map((factor, index2) => (
                                <React.Fragment key={'factor_frag'+index2}>
                                <TableRow key={'factor' + index2}>
                                    <TableCell className={clsx({[classes.cellStyle]: true, [classes.metricsCell]: true})}
                                        rowSpan={factor.length}>{factor.name}</TableCell>
                                </TableRow>
                                {stableSort(factor.variables, getComparator(sortRowsDir, sortRowsBy), sortRowsBy).map((variable, index3) => (
                                    <React.Fragment key={'variable_frag'+index3}>
                                        <TableRow key={'variable' + index3}>
                                            <Tooltip title={variable.name}>
                                                <TableCell
                                                    className={clsx({[classes.cellStyle]: true, [classes.variableCell]: true, [classes.metricsCell]: true})}
                                                    rowSpan={variable.length}
                                                    onClick={(e) => handleClick(e, variable.code, metrics, setMetrics)}>
                                                        { index > 0 &&
                                                            <Checkbox
                                                                classes={{"root": classes.checkboxStyler}}
                                                                checked={isSelectedMetrics(variable.code)}/>
                                                        }
                                                        { variable.name.length > (1+2*(variable.length-2))*19 ?
                                                            variable.name.substring(0, (1+2*(variable.length-2))*19) + '...' :
                                                            variable.name
                                                        }
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                        {variable.rows.map((row, index4) => (
                                            <TableRow key={'row' + index4}>
                                                <TableCell className={classes.cellStyle}>{row.name}</TableCell>
                                                {columnOrder.map((ind, index5) => (
                                                    <TableCell
                                                        key={'cell_' + index5}
                                                        className={clsx({[classes.cellStyle]: true,
                                                                         [classes.filteredCell]: checkFilter(row.value[ind-1], row.index, ind-1)})}>
                                                        {row.value[ind-1] !== -1 && row.valueType === 'percent' && `${Math.floor(row.value[ind-1]*1000.)/10}%`}
                                                        {row.value[ind-1] !== -1 && row.valueType === 'unitless' && `${row.value[ind-1]}`}
                                                        {row.value[ind-1] === -1 && ' '}
                                                        {(row.index && row.index[ind-1] !== -1) && ` (${row.index[ind-1]})`}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                                </React.Fragment>
                            ))} 
                            </React.Fragment>
                        ))}

                        {crosstabCluster.map((dimension, index) => (
                            <React.Fragment key={'dimension_frag'+index}>
                            <TableRow key={'dimension' + index}>
                                <TableCell
                                    className={clsx({[classes.cellStyle]: true, [classes.clusterCell]: true, [classes.borderCell]: (index === 0)})}
                                    rowSpan={dimension.length}>{dimension.name}</TableCell>
                            </TableRow>
                            {dimension.factors.map((factor, index2) => (
                                <React.Fragment key={'factor_frag'+index2}>
                                <TableRow key={'factor' + index2}>
                                    <TableCell 
                                        className={clsx({[classes.cellStyle]: true, [classes.clusterCell]: true, [classes.borderCell]: (index + index2 === 0)})}
                                        rowSpan={factor.length}>{factor.name}</TableCell>
                                </TableRow>
                                {stableSort(factor.variables, getComparator(sortRowsDir, sortRowsBy), sortRowsBy).map((variable, index3) => (
                                    <React.Fragment key={'variable_frag'+index3}>
                                        <TableRow key={'variable' + index3}>
                                            <Tooltip title={variable.name}>
                                                <TableCell
                                                    className={clsx({[classes.cellStyle]: true, [classes.clusterCell]: true,
                                                                    [classes.variableCell]: true, [classes.borderCell]: (index + index2 + index3 === 0)})}
                                                    rowSpan={variable.length}
                                                    onClick={(e) => handleClick(e, variable.code, cluster, setCluster)}>
                                                        <Checkbox
                                                            classes={{"root": classes.checkboxStyler}}
                                                            checked={isSelectedCluster(variable.code)}/>
                                                        { variable.name.length > (1+2*(variable.length-2))*19 ?
                                                            variable.name.substring(0, (1+2*(variable.length-2))*19) + '...' :
                                                            variable.name
                                                        }
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                        {variable.rows.map((row, index4) => (
                                            <TableRow key={'row' + index4}>
                                                <TableCell
                                                    className={clsx({[classes.cellStyle]: true,[classes.borderCell]: (index + index2 + index3 + index4 === 0)})}>
                                                    {row.name}</TableCell>
                                                {columnOrder.map((ind, index5) => (
                                                    <TableCell
                                                        key={'cell_' + index5}
                                                        className={clsx({[classes.cellStyle]: true,
                                                                         [classes.filteredCell]: checkFilter(row.value[ind-1], row.index, ind-1),
                                                                         [classes.borderCell]: (index + index2 + index3 + index4 === 0)})}>
                                                        {row.value[ind-1] !== -1 && row.valueType === 'percent' && `${Math.floor(row.value[ind-1]*1000.)/10}%`}
                                                        {row.value[ind-1] !== -1 && row.valueType === 'unitless' && `${row.value[ind-1]}`}
                                                        {row.value[ind-1] === -1 && ' '}
                                                        {(row.index && row.index[ind-1] !== -1) && ` (${row.index[ind-1]})`}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                                </React.Fragment>
                            ))} 
                            </React.Fragment>
                        ))}
                        {crosstabProfile.map((dimension, index) => (
                            <React.Fragment key={'dimension_frag'+index}>
                            <TableRow key={'dimension' + index}>
                                <TableCell className={clsx({[classes.cellStyle]: true, [classes.profileCell]: true, [classes.borderCell]: (index === 0)})}
                                    rowSpan={dimension.length}>{dimension.name}</TableCell>
                            </TableRow>
                            {dimension.factors.map((factor, index2) => (
                                <React.Fragment key={'factor_frag'+index2}>
                                <TableRow key={'factor' + index2}>
                                    <TableCell className={clsx({[classes.cellStyle]: true, [classes.profileCell]: true, [classes.borderCell]: (index + index2 === 0)})}
                                        rowSpan={factor.length}>{factor.name}</TableCell>
                                </TableRow>
                                {stableSort(factor.variables, getComparator(sortRowsDir, sortRowsBy), sortRowsBy).map((variable, index3) => (
                                    <React.Fragment key={'variable_frag'+index3}>
                                        <TableRow key={'variable' + index3}>
                                            <Tooltip title={variable.name}>
                                                <TableCell
                                                    className={clsx({[classes.cellStyle]: true, [classes.variableCell]: true, [classes.profileCell]: true,
                                                                    [classes.borderCell]: (index + index2 + index3 === 0)})}
                                                    rowSpan={variable.length}
                                                    onClick={(e) => handleClick(e, variable.code, profile, setProfile)}>
                                                        <Checkbox
                                                            classes={{"root": classes.checkboxStyler}}
                                                            checked={isSelectedProfile(variable.code)}/>
                                                        { variable.name.length > (1+2*(variable.length-2))*19 ?
                                                            variable.name.substring(0, (1+2*(variable.length-2))*19) + '...' :
                                                            variable.name
                                                        }
                                                </TableCell>
                                            </Tooltip>
                                        </TableRow>
                                        {variable.rows.map((row, index4) => (
                                            <TableRow key={'row' + index4}>
                                                <TableCell className={clsx({[classes.cellStyle]: true, [classes.borderCell]: (index + index2 + index3 + index4 === 0)})}>{row.name}</TableCell>
                                                {columnOrder.map((ind, index5) => (
                                                    <TableCell
                                                        key={'cell_' + index5}
                                                        className={clsx({[classes.cellStyle]: true,
                                                                         [classes.borderCell]: (index + index2 + index3 + index4 === 0),
                                                                         [classes.filteredCell]: checkFilter(row.value[ind-1], row.index, ind-1)})}>
                                                        {row.value[ind-1] !== -1 && row.valueType === 'percent' && `${Math.floor(row.value[ind-1]*1000.)/10}%`}
                                                        {row.value[ind-1] !== -1 && row.valueType === 'unitless' && `${row.value[ind-1]}`}
                                                        {row.value[ind-1] === -1 && ' '}
                                                        {(row.index && row.index[ind-1] !== -1) && ` (${row.index[ind-1]})`}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                                </React.Fragment>
                            ))} 
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AMVHeader
                filterCriteriaValue={filterCriteriaValue}
                setFilterCriteriaValue={setFilterCriteriaValue}
                filterThresholdValue={filterThresholdValue}
                setFilterThresholdValue={setFilterThresholdValue}

                filterCriteriaIndex={filterCriteriaIndex}
                setFilterCriteriaIndex={setFilterCriteriaIndex}
                filterThresholdIndex={filterThresholdIndex}
                setfilterThresholdIndex={setFilterThresholdIndex}
                sortRowsBy={sortRowsBy}
                setSortRowsBy={setSortRowsBy}
                sortRowsDir={sortRowsDir}
                setSortRowsDir={setSortRowsDir}
                sortColsBy={sortColsBy}
                setSortColsBy={setSortColsBy}
                sortColsDir={sortColsDir}
                setSortColsDir={setSortColsDir}
                setOrder={setOrder}
                columnSortData={columnSortData}
            />
            <TableContainer component={Paper} classes={{root: classes.tableHeight}} >
                <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <SortableList axis='x' lockAxis='x' onSortEnd={onSortEnd} distance={1}
                            genericItems={['Variable', 'Response']}
                            sortItems={columnOrder} headerLen={columnOrder.length}
                            headerDescriptions={headerDescriptions} setHeaderDescriptions={setHeaderDescriptions}/>
                    </TableHead>
                    <TableBody>
                        {stableSortAMV(amvList, getComparator(sortRowsDir, sortRowsBy), sortRowsBy).map((variable, index3) => (
                            <React.Fragment key={'variable_frag'+index3}>
                                <TableRow key={'variable' + index3}>
                                    <Tooltip title={variable.name}>
                                        <TableCell
                                            className={clsx({[classes.cellStyle]: true, [classes.clusterCell]: true,
                                                            [classes.variableCell]: true})}
                                            rowSpan={variable.length}
                                            onClick={(e) => handleClick(e, variable.code, cluster, setCluster)}>
                                                <Checkbox
                                                    classes={{"root": classes.checkboxStyler}}
                                                    checked={isSelectedCluster(variable.code)}/>
                                                { variable.name.length > (1+2*(variable.length-2))*19 ?
                                                    variable.name.substring(0, (1+2*(variable.length-2))*19) + '...' :
                                                    variable.name
                                                }
                                        </TableCell>
                                    </Tooltip>
                                </TableRow>
                                {variable.rows.map((row, index4) => (
                                    <TableRow key={'row' + index4}>
                                        <TableCell
                                            className={clsx({[classes.cellStyle]: true})}>
                                            {row.name}</TableCell>
                                        {columnOrder.map((ind, index5) => (
                                            <TableCell
                                                key={'cell_' + index5}
                                                className={clsx({[classes.cellStyle]: true,
                                                                 [classes.filteredCell]: checkFilter(row.value[ind-1], row.index, ind-1)})}>
                                                {row.value[ind-1] !== -1 && row.valueType === 'percent' && `${Math.floor(row.value[ind-1]*1000.)/10}%`}
                                                {row.value[ind-1] !== -1 && row.valueType === 'unitless' && `${row.value[ind-1]}`}
                                                {row.value[ind-1] === -1 && ' '}
                                                {(row.index && row.index[ind-1] !== -1) && ` (${row.index[ind-1]})`}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <SummaryHeader/>
            <TableContainer component={Paper} classes={{root: classes.tableHeight}} >
                <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCellStyle}>{' '}</TableCell>
                            { this.state.probabilities.map((row, index) => (
                                <TableCell key={`p-header-${index+1}`} className={classes.headCellStyle}>  
                                    <Box className={classes.moveHeadInside}>
                                        <Box className={classes.halfBoxStart}>
                                            {`Predicted SEG${index+1}`}
                                        </Box>
                                        <Box className={classes.halfBoxEnd}>
                                            <Tooltip title={headerDescriptions[index]}>
                                                <InfoIcon />
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.probabilities.map((row, index) => (
                            <TableRow key={'row' + index}>
                                <TableCell className={classes.headCellStyle}>{`Actual SEG${index+1}`}</TableCell>
                                { row.map((val, index2) => (
                                    <TableCell className={clsx({[classes.cellStyle]: true})}>{`${Math.floor(val*10000.)/100}%`}</TableCell>
                                ))}
                            </TableRow> 
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )}
}

export default withStyles(useStyles)(RunDetailSummaryComponent)