/*
    Specify the API end points for Django into a file
*/

export const paths = {
    home: '/',
    login: '/login/',
    forgotPassword: '/forgotPassword/',
    logout: '/logout/',
    resetPassword: '/resetPassword/',
    project: '/project/',

    apiFirstName: '/api/firstname/',
    apiChangePW: '/api/changePW/',
    apiCheckKey: '/api/checkKey/',
    apiProjectList: '/api/projectList/',
    apiProjectLoad: '/api/project/load/',

    apiProjectDownloadData: '/api/project/download/data/',
    apiProjectDownloadVariable: '/api/project/download/variable/',
    apiProjectDownloadDiscretized: '/api/project/download/discretized/',
    apiProjectDelete: '/api/project/delete/',

    apiProjectNewRun: '/api/project/run/new/',
    apiRunList: '/api/runList/',
    apiRunLoad: '/api/run/load/',
    apiRunDateLoad: '/api/run/load/date/',
    apiRunProbabilityLoad: '/api/run/load/probability/',
    apiRunUpdateDescriptions: '/api/run/update/description/',
    apiRunSaveConfig: '/api/run/update/config/',
    apiRunDelete: '/api/run/delete/',
    apiRunDownloadRawProb: '/api/run/download/rawprob/',
    apiRunDownloadCrosstab: '/api/run/download/crosstab/csv/',
    apiRunDownloadCrosstabExcel: '/api/run/download/crosstab/excel/',
    apiRunTypingToolPredict: '/api/run/typingtool/predict/',
    apiRunTypingToolPredictProbability: '/api/run/typingtool/predict/probability/',
    apiRunTypingToolTopK: '/api/run/typingtool/topk/',
    apiRunTypingToolTopKDownloadCSV: '/api/run/typingtool/topk/download/output/',
    apiRunTypingToolTopKDownloadClassifier: '/api/run/typingtool/topk/download/classifier/',
    apiRunTypingToolTopKDownloadClassifierProbability: '/api/run/typingtool/topk/download/classifier/probability/',
    apiRunTypingToolTopKDownloadClassify: '/api/run/typingtool/topk/download/classify/',

    apiResultPing: '/api/result/ping/',
    apiStatusPing: '/api/status/ping/',
    apiFileDownloadPing: '/api/filedownload/csv/ping/',
    apiFileExcelDownloadPing: '/api/filedownload/xlsx/ping/',
    apiPredFileDownloadPing: '/api/preddownload/ping/',
    apiClassifierPing: '/api/classifier/ping/',
};