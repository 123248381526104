/*
    Modal that confirms save when trying to download the crosstab data
    This will only be triggered if downloading a file and editMode is active
*/

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Dialog,
        DialogContent,
        DialogContentText,
        IconButton,
        Typography,
        Grow,
        Button,
        Box} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        minWidth: 400,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    submitButtonWrapper: {
        width: '50%',
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
    },
    fieldViewBox: {
        marginRight: 10,
        marginLeft: 10,
    },
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function RunSaveConfirmationModal(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {
        open,
        handleClose,
        saveConfiguration,
        downloadType,
        downloadCrosstab,
        downloadCrosstabExcel,

    } = props;

    // handle the download proceed (saved or unsaved)
    const proceedDownload = async (saveRequest) => {
        if (saveRequest) {
            await saveConfiguration() // save if pressing save button
        }
        if (downloadType === 'csv') {
            // !saveRequest lets the download unsaved bypass the conditional
            // in the function
            downloadCrosstab(!saveRequest)
        }
        else {
            // !saveRequest lets the download unsaved bypass the conditional
            // in the function
            downloadCrosstabExcel(!saveRequest)
        }
        handleClose()
    }

    return (
        <Dialog
            open = {open}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <DialogTitle classes={classes} id="alert-about-title">
                {"Unsaved Crosstab Changes"}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-about-description">
                    You have unsaved changes applied to the crosstabs. Unsaved changes will not be reflected in the downloaded file.
                </DialogContentText>
            </DialogContent>
            <Box className={classes.fieldView}>
                <Box className={classes.fieldViewBox}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => proceedDownload(true)}>
                        Save and Download
                    </Button>
                </Box>
                <Box className={classes.fieldViewBox}>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => proceedDownload(false)}>
                        Download Unsaved
                    </Button>
                </Box>
                <Box className={classes.fieldViewBox}>
                    <Button
                        color='secondary'
                        onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

