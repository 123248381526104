/*
    The main header that all authenticated users will have
*/

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import {paths} from '../PathContext';
import Logo from '../images/Rosemark_reverse.png';
import MainHeaderAbout from './MainHeaderAbout';
import axios from 'axios';

const imageStyle = {
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
}

// style for the React components in the rest of the header
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menu: {
        backgroundColor: theme.palette.primary,
        position: 'fixed', // MAKES THIS STICKY! The header height is 64
        top: 0, // ALWAYS OFFSET BY 64!
        left: 0,
    },
    logoStyle: {
        marginLeft: theme.spacing(1),
        width: 200
    },
    greetings: {
        color: 'white',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: 30,
        fontSize: 18
    },
    buttonBox: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    menuButton: {
        marginRight: theme.spacing(1),
        height: 50,
        width: 80,
        minWidth: 120,
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
    },
}));

function checkTime() {
    var currTime = new Date();
    const hour = currTime.getHours();
    if (hour < 12 && hour >= 6) {
        return 'Good Morning, '
    } else if (hour < 18 && hour >= 12) {
        return 'Good Afternoon, '
    } else {
        return 'Good Evening, '
    }
}

export default function MainHeader(props) {
    const classes = useStyles();

    // opening the About page
    const [openAbout, setOpenAbout] = useState(false);
    const [message, setMessage] = useState('');

    const handleClickOpenAbout = () => {
        setOpenAbout(true);
    };

    const handleCloseAbout = () => {
        setOpenAbout(false);
    };

    const handleLogout = () => {
        window.location.href = paths.logout
    };

    const handleHome = () => {
        window.location.href = paths.home
    }

    useEffect(() => {
        axios.get(paths.apiFirstName, {})
            .then((response) => {
                setMessage(checkTime() + response.data.first_name);
        }, [])
    })

    // render the header
    return (
        <Box className={classes.root}>
            <AppBar position="static" className={classes.menu}>
                <Toolbar>
                    <Box className={classes.logoStyle} onClick={handleHome}>
                        <img src={Logo} alt="Rosemark Logo" style={imageStyle}/>
                    </Box>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.greetings}>{message}</Typography>
                        <Button color="inherit" className={classes.menuButton} onClick={handleClickOpenAbout}>About</Button>
                        <MainHeaderAbout open={openAbout} handleClose={handleCloseAbout}/>
                        <Button color="inherit" className={classes.menuButton} onClick={handleLogout}>Logout</Button>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}