/*
    Contains the overview (run details) tab content
    Here, you can duplicate and delete the run
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';

import axios from 'axios';
import clsx from 'clsx';

import {paths} from '../../PathContext'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = theme => ({
    row: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        marginTop: 15,
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    runName: {
        fontSize: 30,
        fontWeight: 800,
        marginLeft: 50,
        marginRight: 20,
    },
    otherInfo: {
        fontSize: 22,
        textAlign: 'center',
        marginLeft: 20,
        marginRight: 20,
    },
    runDescription: {
        fontSize: 20,
        fontWeight: 400,
        width: '100%',
        marginLeft: 50,
        marginRight: 50,
    },

    tableRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 25,
    },
    tableWrap: {
        flex: 1,
        marginLeft: 10,
        marginRight: 10,
    },
    tableTitle: {
        fontSize: 24,
    },
    tableHeight: {
        maxHeight: '85vh',
        overflowX: 'auto',
        marginBottom: 40,
    },
    table: {
    },
    headCellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        maxWidth: '10vw',
        minWidth: '7vw',
        fontSize: 15,
    },
    cellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: '10vw',
        minWidth: '7vw',
        fontSize: 15,
    },
    borderCell: {
        borderTop: '2px solid rgba(50, 50, 50, 1)'
    },
})

class RunDetailOverviewComponent extends React.Component {
    constructor(props) {
        super()

        this.state = {
            createdDate: '',
            updatedDate: ''
        }
    }

    async componentDidMount() {
        this.props.setLoading(true)
        await axios.get(paths.apiRunDateLoad + this.props.runID + '/').then((response) => {
            this.setState({
                createdDate: response.data.createdDate,
                updatedDate: response.data.updatedDate
            })
        })
        this.props.setLoading(false)
    }

    render() {
    const {
        classes,
    } = this.props

    return (
        <React.Fragment>
            <Box className={classes.row}>
                <Typography className={classes.runName}>{this.props.name}</Typography>
                <Typography className={classes.otherInfo}>{`Created: ${this.state.createdDate}`}</Typography>
                <Typography className={classes.otherInfo}>{`Updated: ${this.state.updatedDate}`}</Typography>
                <Typography className={classes.otherInfo}>{`Max. Clusters: ${this.props.clusterNum}`}</Typography>
            </Box>
            <Box className={classes.row}>
                <Typography className={classes.runDescription}>{this.props.description}</Typography>
            </Box>
            <Box className={classes.tableRow}>
                <Box className={classes.tableWrap}> 
                    <Toolbar>
                        <Typography className={classes.tableTitle}>{`Cluster Variables (${this.props.clusterLength})`}</Typography>
                    </Toolbar>
                    <TableContainer component={Paper} classes={{root: classes.tableHeight}}>
                        <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headCellStyle}>Dimension</TableCell>
                                    <TableCell className={classes.headCellStyle}>Factor</TableCell>
                                    <TableCell className={classes.headCellStyle}>Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { this.props.crosstabCluster.map((dimension, index) => (
                                    <React.Fragment>
                                        { dimension.factors.map((factor, index2) => (
                                            <React.Fragment>
                                                { factor.variables.map((variable, index3) => (
                                                    <TableRow>
                                                        <TableCell className={classes.cellStyle}>{dimension.name}</TableCell>
                                                        <TableCell className={classes.cellStyle}>{factor.name}</TableCell>
                                                        <TableCell className={classes.cellStyle}>{variable.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box className={classes.tableWrap}>
                    <Toolbar>
                        <Typography className={classes.tableTitle}>
                            {`Metrics Variables (${this.props.metricsLength}) & Profile Variables (${this.props.profileLength})`}
                        </Typography>
                    </Toolbar>
                    <TableContainer component={Paper} classes={{root: classes.tableHeight}}>
                        <Table classes={{root: classes.table}} aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headCellStyle}>Dimension</TableCell>
                                    <TableCell className={classes.headCellStyle}>Factor</TableCell>
                                    <TableCell className={classes.headCellStyle}>Variable</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { this.props.crosstabMetrics.slice(1).map((dimension, index) => (
                                    <React.Fragment>
                                        { dimension.factors.map((factor, index2) => (
                                            <React.Fragment>
                                                { factor.variables.map((variable, index3) => (
                                                    <TableRow>
                                                        <TableCell className={clsx({[classes.cellStyle]: true})}>{dimension.name}</TableCell>
                                                        <TableCell className={clsx({[classes.cellStyle]: true})}>{factor.name}</TableCell>
                                                        <TableCell className={clsx({[classes.cellStyle]: true})}>{variable.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                                { this.props.crosstabProfile.map((dimension, index) => (
                                    <React.Fragment>
                                        { dimension.factors.map((factor, index2) => (
                                            <React.Fragment>
                                                { factor.variables.map((variable, index3) => (
                                                    <TableRow>
                                                        <TableCell className={clsx({[classes.cellStyle]: true,
                                                            [classes.borderCell]: (index + index2 + index3 === 0)})}>{dimension.name}</TableCell>
                                                        <TableCell className={clsx({[classes.cellStyle]: true,
                                                            [classes.borderCell]: (index + index2 + index3 === 0)})}>{factor.name}</TableCell>
                                                        <TableCell className={clsx({[classes.cellStyle]: true,
                                                            [classes.borderCell]: (index + index2 + index3 === 0)})}>{variable.name}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </React.Fragment>
    )
}}

export default withStyles(useStyles)(RunDetailOverviewComponent)