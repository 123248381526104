/*
    Main body of the landing page after logging in. This willl show 
    the list of projects associated with the account.
*/

import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';

import Loading from '../Loading'
import ChangePassword from './ChangePassword';
import ProjectAdd from './ProjectAdd';
import {paths} from '../../PathContext';
import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

// style for the React components in the rest of the header
const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        flexDirection: 'column',
        marginTop: 90,
    },
    headingRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
    headingRowLeft: {
        flexDirection: 'column',
        paddingLeft: 50,
        flex: 1,
    },
    headingRowRight: {
        paddingRight: 50,
    },
    title: {
        fontSize: 40,
        fontWeight: 900
    },
    subtitle: {
        fontSize: 20,
        marginTop: 10,
    },
    changePWFont: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 18,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        color: 'white'
    },
    submitButtonWrapper: {
        backgroundColor: theme.palette.tertiary.main,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark
        }
    },

    projectHeadingWrapper: {
        marginTop: 50,
        paddingLeft: 50,
        paddingRight: 50,
    },
    projectHeading: {
        fontSize: 36,
        fontWeight: 600,
        marginBottom: 5,
    },
    divider: {
        backgroundColor: '#3B3F3C'
    },

    gridWrapper: {
        marginTop: 20,
        marginLeft: 50,
        marginRight: 50
    },
    paper: {
        maxWidth: 400,
        margin: `${theme.spacing(3)}px auto`,
        padding: theme.spacing(3),
        border: '2px solid transparent',
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}`,
            cursor: 'pointer',
        },
        color: 'white',
        backgroundColor: theme.palette.dark.main,
    },
    paperTitle: {
        fontSize: 20,
        fontWeight: 800,
        marginBottom: 3,
    },
    paperDates: {
        fontSize: 16,
        marginTop: 8,
    },
    paperDetail: {
        fontSize: 16,
        marginTop: 22,
    },
    paperAdd: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        border: `2px solid ${theme.palette.dark.main}`,
        backgroundColor: 'white',
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}`,
            cursor: 'pointer',
        },
        height: 138,
        maxWidth: 400,
        margin: `${theme.spacing(3)}px auto`,
        padding: theme.spacing(3),
    },
}));

export default function HomeContent(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [openPWChange, setOpenPWChange] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);

    const handleClickPWChange = () => {
        setOpenPWChange(true);
    };

    const handleClosePWChange = () => {
        setOpenPWChange(false);
    };

    const handleClickNew = () => {
        setOpenAdd(true);
    };

    const handleCloseNew = () => {
        setOpenAdd(false);
    };

    const redirectProfile = (project_id) => () => {
        window.scrollTo(0, 0);
        window.location.assign(paths.project + project_id + '/');
    }

    useEffect(() => {
        async function loadData() {
            setLoading(true)
            await axios.get(paths.apiProjectList).then((response) => {
                setData(response.data)
            })
            setLoading(false)
        }
        loadData()
    }, [])

    // render the header
    return (
        <Box className={classes.root}>
            <Loading open={loading}/>
            <Box className={classes.headingRow}>
                <Box className={classes.headingRowLeft}>
                    <Typography className={classes.title}>
                        Welcome to the Quantitative Persona (QP) Tool!
                    </Typography>
                    <Typography className={classes.subtitle}>Select an existing project or create a new project to get started.</Typography>
                </Box>
                <Box className={classes.headingRowRight}>
                     <Button
                        variant='contained'
                        className={classes.submitButtonWrapper}
                        onClick={handleClickPWChange}>
                        <Typography className={classes.changePWFont}>Change Password</Typography>
                    </Button>
                    <ChangePassword open={openPWChange} handleClose={handleClosePWChange}/>
                </Box>
            </Box>

            <Box className={classes.projectHeadingWrapper}>
                <Typography className={classes.projectHeading}>Projects ({data.length})</Typography>
                <Divider className={classes.divider}/>
            </Box>

            <Box className={classes.gridWrapper}>
                <Grid container spacing = {6}>
                    { data.map((entry, index) => 
                        <Grid item xs={6} sm={6} lg={4} xl={2} key={'grid'+index}>
                            <Paper
                                className={classes.paper}
                                elevation={4}
                                key={index}
                                onClick={redirectProfile(entry.project_id)}>
                                <Typography className={classes.paperTitle}>{entry.name}</Typography>
                                <Typography className={classes.paperDates}>Created {entry.created_date}</Typography>
                                <Typography className={classes.paperDates}>Last Updated {entry.updated_date}</Typography>
                                <Typography className={classes.paperDetail}>{entry.runs} runs completed</Typography>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={6} sm={6} lg={4} xl={2}>
                        <Paper
                            className={classes.paperAdd}
                            onClick={handleClickNew}>
                            <AddIcon/>
                            <Typography>Create a New Project</Typography>
                        </Paper>
                    </Grid>
                    <ProjectAdd open={openAdd} handleClose={handleCloseNew} data={data}/>
                </Grid>
            </Box>
        </Box>
    );
}