import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';

// searching mechanism
import Fuse from 'fuse.js'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        minWidth: '80%',
        display: 'flex',
    },
    rootConfirm: {
        borderRadius: 10,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
    },
    fieldViewBox: {
        marginRight: 10,
        marginLeft: 10,
    },
    tableContainer: {
        maxHeight: 600,
    },
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

/*
    Specify the table itself on top of the dialog modal
*/
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
/*
    Use the above three methods as a table header
*/
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
            <Tooltip title='Select All'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all rows' }}
          />
          </Tooltip>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell}
              direction={orderBy === headCell ? order : 'asc'}
              onClick={createSortHandler(headCell)}
            >
              {headCell}
              {orderBy === headCell ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/*
    The ToolBar at the top displaying
*/

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    runIndex,
    selected,
    handleSelectedList,
    handleClose,
    disableSet,
    type,
    setPage,
    setUpdated} = props;

  const handleSave = async () => {
    await handleSelectedList({type: 'update', index: runIndex, value: selected})
    handleClose()
    setPage(0)
    setUpdated(false)
  }

  var total = numSelected
  if (type === 'profile') {
    total += disableSet.length
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: total > 0,
      })}
    >
      {total > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {total} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Select Variables
        </Typography>
      )}

      {total > 0 && (
        <Tooltip title='Save Selection'>
          <IconButton aria-label="save"
            onClick={handleSave}>
            <CheckIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};


export default function VariableModal(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {
        open,
        handleClose,
        variableDict,
        variableHeaders,
        type,
        runIndex,
        selectedList,
        handleSelectedList,
        disableSet} = props;

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Variable');
    const [selected, setSelected] = React.useState(selectedList);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [updated, setUpdated] = React.useState(false);
    const [closeConfirmation, setCloseConfirmation] = React.useState(false);

    // for searching and filtering the list
    const [filteredList, setFilteredList] = React.useState(variableDict);
    const [searchVal, setSearchVal] = React.useState('');

    const fuse = new Fuse(variableDict, {keys: ['Variable', 'Variable Label'], threshold: 0.2})

    const closeModalReset = () => {
        if (!updated) {
            setPage(0)
            setUpdated(false)
            handleClose()
        }
        else {
            setCloseConfirmation(true)
        }
    }

    const closeModalUnsaved = async () => {
        handleClose()
        setPage(0)
        setUpdated(false)
        setCloseConfirmation(false)
    }

    const closeModalSave = async () => {
        await handleSelectedList({type: 'update', index: runIndex, value: selected})
        handleClose()
        setPage(0)
        setUpdated(false)
        setCloseConfirmation(false)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        setUpdated(true)
        if (event.target.checked) {
            if (selected.length === 0) {
                var newSelecteds = filteredList.map((n) => n.Variable);
                newSelecteds = newSelecteds.filter(function (item) {
                    // check to see if there is a disableSet
                    // if so, then filter these out to prevent double-counting of variables
                    if (disableSet) {
                        return disableSet.indexOf(item) < 0
                    }
                    return true
                })
                setSelected(newSelecteds);
                return;
            }
        }
        setSelected([]);
    };

    const handleSearch = (event) => {
        setSearchVal(event.target.value)
        if (event.target.value === '') {
            setFilteredList(variableDict)
        }
        else {
            const searchResult = fuse.search(event.target.value)
            setFilteredList(searchResult.map((val) => (val.item)))
        }
    }

    const handleClick = (event, name) => {

        if (type === 'profile' && disableSet.indexOf(name) !== -1)
            return

        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setUpdated(true)
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const isCluster = (name) => disableSet.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredList.length - page * rowsPerPage);

    return (
        <React.Fragment>
        {!closeConfirmation ?
        <Dialog
            open = {open}
            onClose = {closeModalReset}
            onEnter={async () => {await setSelected(selectedList)}}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <DialogTitle classes={classes} id="alert-about-title" onClose = {closeModalReset}>
                { type === 'cluster' && "Cluster Variables"}
                { type === 'profile' && "Profile Variables"}
                { type === 'metrics' && "Metrics Variables"}
            </DialogTitle>
            <DialogContent dividers>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    runIndex={runIndex}
                    selected={selected}
                    handleSelectedList={handleSelectedList}
                    handleClose={handleClose}
                    disableSet={disableSet}
                    type={type}
                    setPage={setPage}
                    setUpdated={setUpdated}/>
                <TableContainer className={classes.tableContainer}>
                    <TextField
                        fullWidth
                        value={searchVal}
                        label='Search variable...'
                        onChange={handleSearch}
                    />
                    <Table
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={filteredList.length}
                            headCells={variableHeaders}
                        />
                        <TableBody>
                            {stableSort(filteredList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    var isItemSelected = isSelected(row.Variable);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    if (type === 'profile') {
                                        isItemSelected = isItemSelected || isCluster(row.Variable)
                                    }

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.Variable)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.Variable}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    disabled={type === 'profile' && isCluster(row.Variable)}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row">
                                                {row.Variable}
                                            </TableCell>
                                            <TableCell align="left">{row['Variable Label']}</TableCell>
                                            <TableCell align="left">{row.Dimension}</TableCell>
                                            <TableCell align="left">{row.Factor}</TableCell>
                                            <TableCell align="left">{row['Data Type']}</TableCell>
                                        </TableRow>
                                )})}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={filteredList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </DialogContent>

        </Dialog>
        :
            <Dialog
                open = {open}
                TransitionComponent = {Transition}
                aria-labelledby="alert-about-title"
                aria-describedby="alert-about-description"
                classes={{paper: classes.rootConfirm}}
            >
                <DialogTitle classes={classes} id="alert-about-title">
                    {"Unsaved Changes"}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-about-description">
                        You have unsaved changes. Would you like to save?
                    </DialogContentText>
                </DialogContent>
                <Box className={classes.fieldView}>
                    <Box className={classes.fieldViewBox}>
                        <Button
                            variant='contained'
                            color='primary'
                            classes={{contained: classes.submitButton}}
                            className={classes.submitButtonWrapper}
                            onClick={closeModalSave}>
                            <Typography className={classes.submitFontSize}>Save Changes</Typography>
                        </Button>
                    </Box>
                    <Box className={classes.fieldViewBox}>
                    <Button
                        variant='outlined'
                        color='secondary'
                        onClick={closeModalUnsaved}>
                        <Typography className={classes.submitFontSize}>Discard Changes</Typography>
                    </Button>
                    </Box>
                    <Box className={classes.fieldViewBox}>
                    <Button
                        color='primary'
                        onClick={() => {setCloseConfirmation(false)}}>
                        <Typography className={classes.submitFontSize}>Return to Selection</Typography>
                    </Button>
                    </Box>
                </Box>
            </Dialog>
        }
        </React.Fragment>
    );
};

