/*
    Primarily uses the project-level and run-level folders to put together
    the core crux of the QP Tool. See documentation for details of how this is linked
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteForever from '@material-ui/icons/DeleteForever';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { Link } from "@reach/router"
import axios from 'axios';
import {paths} from '../PathContext';

import Loading from './Loading';
import RunDesignComponent from './project-level/RunDesignComponent';
import DeleteProjectModal from './project-level/DeleteProjectModal';
import RunListComponent from './project-level/RunListComponent';
import RunDetailComponent from './run-level/RunDetailComponent';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

// style for the React components in the rest of the header
const useStyles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        flexDirection: 'column',
        marginTop: 90,
    },
    headingRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
    headingRowLeft: {
        display: 'flex',
        paddingLeft: 40,
        flex: 1,
        flexdirection: 'row',
        alignItems: 'center'
    },
    headingRowRight: {
        paddingRight: 50,
        marginRight: 10,
    },
    title: {
        marginLeft: 20,
        fontSize: 36,
        fontWeight: 900
    },
    subtitle: {
        fontSize: 20,
        marginTop: 10,
    },
    downloadButtonFont: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 14,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    outlinedButton: {
        marginLeft: 10,
        marginRight: 10,
    },
    projectHeadingWrapper: {
        borderBottom: '1px solid #e8e8e8',
        marginTop: 50,
        marginLeft: 50,
        marginRight: 50,

        display: 'flex',
    },
    tabFlex: {
        flex: 1,
    },

});

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontSize: 20,
    fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

class ProjectContent extends React.Component {
    constructor(props) {
        super()
        this.state = {
            projectName: 'Project',
            dataFileName: 'Data File',
            variableFileName: 'Variable File',
            discretizedFileName: 'Discretization File',
            profileDict: [],
            metricsDict: [],
            variableHeaders: [],
            clusterDict: [],

            loading: false,
            deleteProject: false,
            tabValue: 0,

            runID: '',
            duplicatedRun: ''
        }

        this.handleTabChange = this.handleTabChange.bind(this)
        this.downloadData = this.downloadData.bind(this)
        this.downloadVariable = this.downloadVariable.bind(this)
        this.downloadDiscretization = this.downloadDiscretization.bind(this)
        this.deleteButtonPress = this.deleteButtonPress.bind(this)
        this.duplicateRun = this.duplicateRun.bind(this)
        this.setLoading = this.setLoading.bind(this)
        this.setRunID = this.setRunID.bind(this)
        this.setTabValue = this.setTabValue.bind(this)
        this.pingLoad = this.pingLoad.bind(this)
    }


    handleTabChange = (event, newValue) => {
        this.setState({
            runID: '',
            duplicatedRun: '',
            tabValue: newValue
        })
    }

    downloadData = async () => {
        this.setState({
            loading: true
        }, async () => {
            await axios.get(paths.apiProjectDownloadData + this.props.projectID + '/').then((response) => {
                window.location.href = response.data.file_url
            });
            this.setState({
                loading: false
            })
        })
    }

    downloadVariable = async () => {
        this.setState({
            loading: true
        }, async () => {
            await axios.get(paths.apiProjectDownloadVariable + this.props.projectID + '/').then((response) => {
                window.location.href = response.data.file_url
            });
            this.setState({
                loading: false
            })
        })
    }

    downloadDiscretization = async () => {
        this.setState({
            loading: true
        }, async () => {
            await axios.get(paths.apiProjectDownloadDiscretized + this.props.projectID + '/').then((response) => {
                window.location.href = response.data.file_url
            });
            this.setState({
                loading: false
            })
        })
    }

    deleteButtonPress = () => {
        this.setState({
            deleteProject: true
        })
    }

    duplicateRun = async (obj) => {
        this.setState({
            duplicatedRun: JSON.stringify(obj),
            tabValue: 0,
            runID: '',
        })
    }

    async pingLoad(jobID) {
        await axios.get(paths.apiResultPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'finished') {
                this.setState({
                    metricsDict: response.data.result.metrics_dict,
                    profileDict: response.data.result.profile_dict,
                    variableDictHeaders: response.data.result.variable_headers,
                    clusterDict: response.data.result.cluster_dict
                })
            }
            else {
                await new Promise(resolve => setTimeout(resolve, 100)); // ping every 0.1 seconds
                await this.pingLoad(jobID)
            }
        })
    }

    async componentDidMount() {
        this.setState({
            loading: true
        }, async () => {
            await axios.get(paths.apiProjectLoad + this.props.projectID + '/').then(async (response) => {
                var data = response.data
                // this will trigger if there is something wrong with the JSON
                if (typeof(response) === 'string') {
                    console.log('There is something wrong with one of the .csv input files!')
                }
                await this.pingLoad(data.job_id)
                this.setState({
                    loading: false,
                    projectName: data.name,
                    dataFileName: data.filename_data,
                    variableFileName: data.filename_variable,
                    discretizedFileName: data.filename_discretized,

                })  
            })
        })
    }

    setLoading = (val) => {this.setState({loading: val})}
    setRunID = (val) => {this.setState({runID: val})}
    setTabValue = (val) => {this.setState({tabValue: val})}

    render() {
    const { classes } = this.props

    // render the header
    return (
        <Box className={classes.root}>
            <Loading open={this.state.loading}/>
            <DeleteProjectModal
                open={this.state.deleteProject}
                handleClose={() => this.setState({deleteProject: false})}
                name={this.state.projectName}
                projectID={this.props.projectID}/>
            <Box className={classes.headingRow}>
                <Box className={classes.headingRowLeft}>
                    <Link to={paths.home}>
                        <IconButton>
                            <ArrowBackIcon />
                        </IconButton>
                    </Link>
                    <Typography className={classes.title}>
                        {this.state.projectName}
                    </Typography>
                </Box>
                <Box className={classes.headingRowRight}>
                     <Button
                        variant='outlined'
                        color='secondary'
                        classes={{'outlined': classes.outlinedButton}}
                        onClick={this.downloadData}>
                        <Typography className={classes.downloadButtonFont}>{this.state.dataFileName}</Typography>
                    </Button>
                    <Button
                        variant='outlined'
                        color='secondary'
                        classes={{'outlined': classes.outlinedButton}}
                        onClick={this.downloadVariable}>
                        <Typography className={classes.downloadButtonFont}>{this.state.variableFileName}</Typography>
                    </Button>
                    <Button
                        variant='outlined'
                        color='secondary'
                        classes={{'outlined': classes.outlinedButton}}
                        onClick={this.downloadDiscretization}>
                        <Typography className={classes.downloadButtonFont}>{this.state.discretizedFileName}</Typography>
                    </Button>
                </Box>
            </Box>

            <Box className={classes.projectHeadingWrapper}>
                <Box className={classes.tabFlex}>
                    <CustomTabs value={this.state.tabValue} onChange={this.handleTabChange}>
                        <CustomTab label='Design Runs'/>
                        <CustomTab label='Results'/>
                    </CustomTabs>
                </Box>
                <IconButton onClick={this.deleteButtonPress}>
                    <DeleteForever />
                </IconButton>
            </Box>
            { this.state.tabValue === 0 ?
                <RunDesignComponent
                    profileDict={this.state.profileDict}
                    metricsDict={this.state.metricsDict}
                    variableHeaders={this.state.variableDictHeaders}
                    clusterDict={this.state.clusterDict}
                    projectID={this.props.projectID}
                    setTabValue={this.setTabValue}
                    duplicatedRun={this.state.duplicatedRun}
                />
                :
                (this.state.runID !== '' ?
                    <RunDetailComponent
                        setRunID={this.setRunID}
                        duplicateRun={this.duplicateRun}
                        runID={this.state.runID}
                        setLoading={this.setLoading}
                    />
                :
                    <RunListComponent
                        setRunID={this.setRunID}
                        projectID={this.props.projectID}
                        setLoading={this.setLoading}
                    />
                )
            }
        </Box>
    );
}}

export default withStyles(useStyles)(ProjectContent)