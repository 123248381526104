import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Link } from "@reach/router";
import qs from 'qs'

import { paths } from '../../PathContext';
import Loading from '../Loading';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    // for the register/login section
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15,
        width: '90%',
    },
    textInput: {
        width: '100%',
        marginTop: 10,
    },
    options: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 5,
        paddingLeft: 0,
        paddingRight: 0
    },
    optionsFontSize: {
        fontSize: 12,
        textDecoration: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
    },
    buttonWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        marginTop: 5,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 20,
    },
    submitButtonWrapper: {
        width: '50%',
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    submitFontSize: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 14
    },
    error: {
        fontSize: 12,
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
    }
})) 

export default function PreLogMain(props) {
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const [rememberCheck, setRememberCheck] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [emailCheck, setEmailCheck] = useState(false);
    const [passwordCheck, setPasswordCheck] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleRemember = () => {
        setRememberCheck(!rememberCheck);
    }

    const onChangeEmail = (e) => {
        if (emailCheck)
            setEmailCheck(false);
        setEmail(e.target.value);
    }

    const onChangePw = (e) => {
        if (passwordCheck)
            setPasswordCheck(false);
        setPassword(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailVal = e.target.email.value
        const passwordVal = e.target.password.value

        if (emailVal !== '' && passwordVal !== '') {
            setErrorMessage('')
            setLoading(true)

            await axios.post(paths.login, qs.stringify({
                email: emailVal,
                password: passwordVal,
                remember_me: rememberCheck,
            })).then((response) => {
                window.location.href=response.data.success
            }, (error) => {
                setErrorMessage(error.response.data.error_message)
            });

            // revert to defaults
            setLoading(false)
            setEmail('');
            setPassword('');
        }
        else {
            if (emailVal === '') {
                setEmailCheck(true)
            }
            if (passwordVal === '') {
                setPasswordCheck(true)
            }
        }
    }

    return (
        <React.Fragment>
            <GoogleReCaptcha />
            <Loading open={loading}/>
            <Container className={classes.contentContainer}>
                <form onSubmit={handleSubmit}>
                <TextField
                    variant='outlined'
                    label='Email'
                    name='email'
                    autoComplete='off'
                    className={classes.textInput}
                    value={email}
                    onChange={(e) => onChangeEmail(e)}
                    error={(errorMessage || emailCheck)}
                />
                <TextField
                    variant='outlined'
                    label='Password'
                    name='password'
                    type='password'
                    autoComplete='off'
                    className={classes.textInput}
                    value={password}
                    onChange={(e) => onChangePw(e)}
                    error={(errorMessage || passwordCheck)}
                />
                <Typography className={classes.error}>{errorMessage}</Typography>
                <Container className={classes.options}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={rememberCheck}
                            onChange={handleRemember}
                            name="remember"
                            color="primary"
                        />
                        }
                        classes={{label: classes.optionsFontSize}}
                        label="Remember me"
                    />
                    <Link to={paths.forgotPassword} className={classes.optionsFontSize}>
                    <Button color='secondary'>
                        <Typography className={classes.optionsFontSize}>Forgot Password?</Typography>
                    </Button>
                    </Link>
                </Container>
                <Container className={classes.buttonWrapper}>
                    <Button
                        variant='contained'
                        color='primary'
                        classes={{contained: classes.submitButton}}
                        className={classes.submitButtonWrapper}
                        type='submit'>
                        <Typography className={classes.submitFontSize}>Log In</Typography>
                    </Button>
                </Container>
                </form>
            </Container>
        </React.Fragment>
    );
}