/*
    Lists all of the runs associated with the project (under the
    run tab)
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';

import axios from 'axios';
import {paths} from '../../PathContext'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = theme => ({
        tabContent: {
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginLeft: 50,
                        marginRight: 50,
                        maxHeight: '90vh',
                        marginBottom: 50,
        },

        tableHeader: {
                fontSize: 18,
                fontWeight: 700,
        },
        tableBody: {
                fontSize: 18,
                cursor: 'pointer'
        },
})

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const days = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
]

const columnHeaders = [
    'Run Name',
    'Run Date',
    'Updated Date',
    '# of Clusters',
    '# of Cluster Vars.',
    '# of Metrics Vars.',
    '# of Profile Vars.',
]

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

class RunListComponent extends React.Component {

    constructor(props) {
        super()
        this.state = {
            order: 'desc',
            orderBy: 'Updated Date',
            runList: []
        }

        this.handleRequestSort = this.handleRequestSort.bind(this)
    }

    handleRequestSort = (event, property) => {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            order: isAsc ? 'desc' : 'asc',
            orderBy: property
        })
    };

    async componentDidMount() {
        this.props.setLoading(true)
        await axios.get(paths.apiRunList+this.props.projectID+'/').then((response) => {
            this.setState({runList: response.data.run_list})
        })
        this.props.setLoading(false)
    }

    render() {
        const {
            order,
            orderBy,
            runList,
        } = this.state

        const { classes } = this.props

        const dateFormatter = (pre_d) => {
            const d = new Date(pre_d)
            const year = d.getFullYear()
            const date = d.getDate()
            const monthName = months[d.getMonth()]
            const dayName = days[d.getDay()]
            return `${dayName}, ${date} ${monthName} ${year}`
        }

        return (
                <Box className={classes.tabContent}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" stickyHeader>
                            <TableHead>
                                <TableRow >
                                {columnHeaders.map((header, index) => (
                                    <TableCell
                                        align={index===0 ? "inherit" : "right"}
                                        classes={{head: classes.tableHeader}}
                                        key={`runList-${index}`}
                                        sortDirection={orderBy === header ? order : false}>
                                        <TableSortLabel
                                                active={orderBy === header}
                                                direction={orderBy === header ? order : 'asc'}
                                                onClick={(e) => this.handleRequestSort(e, header)}>
                                                {header}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stableSort(runList, getComparator(order, orderBy)).map((row) => (
                                    <TableRow key={row['id']} hover onClick={() => this.props.setRunID(row['id'])}>
                                        <TableCell component="th" scope="row" classes={{body: classes.tableBody}}>
                                                {row['Run Name']}
                                        </TableCell>
                                        <TableCell align="right" classes={{body: classes.tableBody}}>{dateFormatter(row['Run Date'])}</TableCell>
                                        <TableCell align="right" classes={{body: classes.tableBody}}>{dateFormatter(row['Updated Date'])}</TableCell>
                                        <TableCell align="right" classes={{body: classes.tableBody}}>{row['# of Clusters']}</TableCell>
                                        <TableCell align="right" classes={{body: classes.tableBody}}>{row['# of Cluster Vars.']}</TableCell>
                                        <TableCell align="right" classes={{body: classes.tableBody}}>{row['# of Metrics Vars.']}</TableCell>
                                        <TableCell align="right" classes={{body: classes.tableBody}}>{row['# of Profile Vars.']}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
        )
}}

export default withStyles(useStyles)(RunListComponent)