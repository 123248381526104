/*
    FOR TYPING TOOL CONTENT: Select segments
*/

import React from 'react';
import clsx from 'clsx';
import { lighten, makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        minWidth: '20%',
        display: 'flex',
    },
    rootConfirm: {
        borderRadius: 10,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
    },
    fieldViewBox: {
        marginRight: 10,
        marginLeft: 10,
    },
    tableContainer: {
        maxHeight: 600,
    },
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

/*
    Use the above three methods as a table header
*/
function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, headCells } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
            <Tooltip title='Select All'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all rows' }}
          />
          </Tooltip>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell}
            align={'left'}
            padding={'default'}
          >{headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

/*
    The ToolBar at the top displaying
*/

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    selected,
    handleSelectedList,
    handleClose,
    setUpdated} = props;

  const handleSave = async () => {
    await handleSelectedList(selected)
    handleClose()
    setUpdated(false)
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Select Variables
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title='Save Selection'>
          <IconButton aria-label="save"
            onClick={handleSave}>
            <CheckIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};


export default function RunDetailVariableModal(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const {
        open,
        handleClose,
        segmentList,
        segmentHeaders,
        selectedList,
        handleSelectedList} = props;

    const [selected, setSelected] = React.useState(selectedList);
    const [updated, setUpdated] = React.useState(false);
    const [closeConfirmation, setCloseConfirmation] = React.useState(false);

    const closeModalReset = () => {
        if (!updated) {
            setUpdated(false)
            handleClose()
        }
        else {
            setCloseConfirmation(true)
        }
    }

    const closeModalUnsaved = async () => {
        handleClose()
        setUpdated(false)
        setCloseConfirmation(false)
    }

    const closeModalSave = async () => {
        await handleSelectedList(selected)
        handleClose()
        setUpdated(false)
        setCloseConfirmation(false)
    }

    const handleSelectAllClick = (event) => {
        setUpdated(true)
        if (event.target.checked) {
            if (selected.length === 0) {
                setSelected(segmentList.filter(entry => entry < segmentList.length));
                return;
            }
        }
        setSelected([]);
    };

    const handleClick = (event, num) => {
        const selectedIndex = selected.indexOf(num);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, num);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setUpdated(true)
        setSelected(newSelected);
    };

    const isSelected = (num) => selected.indexOf(num) !== -1;

    return (
        <React.Fragment>
        {!closeConfirmation ?
        <Dialog
            open = {open}
            onClose = {closeModalReset}
            onEnter={async () => {await setSelected(selectedList)}}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <DialogTitle classes={classes} id="alert-about-title" onClose = {closeModalReset}>
                Segment Selection
            </DialogTitle>
            <DialogContent dividers>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    selected={selected}
                    handleSelectedList={handleSelectedList}
                    handleClose={handleClose}
                    setUpdated={setUpdated}/>
                <TableContainer className={classes.tableContainer}>
                    <Table
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={segmentList.length}
                            headCells={segmentHeaders}
                        />
                        <TableBody>
                            {segmentList.filter(num => num < segmentList.length).map((num) => {
                                    var isItemSelected = isSelected(num);

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, num)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={num}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                />
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {`SEG${num}`}
                                            </TableCell>
                                        </TableRow>
                                )})}

                        </TableBody>
                    </Table>
                </TableContainer>
                
            </DialogContent>

        </Dialog>
        :
            <Dialog
                open = {open}
                TransitionComponent = {Transition}
                aria-labelledby="alert-about-title"
                aria-describedby="alert-about-description"
                classes={{paper: classes.rootConfirm}}
            >
                <DialogTitle classes={classes} id="alert-about-title">
                    {"Unsaved Changes"}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText id="alert-about-description">
                        You have unsaved changes. Would you like to save?
                    </DialogContentText>
                </DialogContent>
                <Box className={classes.fieldView}>
                    <Box className={classes.fieldViewBox}>
                        <Button
                            variant='contained'
                            color='primary'
                            classes={{contained: classes.submitButton}}
                            className={classes.submitButtonWrapper}
                            onClick={closeModalSave}>
                            <Typography className={classes.submitFontSize}>Save Changes</Typography>
                        </Button>
                    </Box>
                    <Box className={classes.fieldViewBox}>
                    <Button
                        variant='outlined'
                        color='secondary'
                        onClick={closeModalUnsaved}>
                        <Typography className={classes.submitFontSize}>Discard Changes</Typography>
                    </Button>
                    </Box>
                    <Box className={classes.fieldViewBox}>
                    <Button
                        color='primary'
                        onClick={() => {setCloseConfirmation(false)}}>
                        <Typography className={classes.submitFontSize}>Return to Selection</Typography>
                    </Button>
                    </Box>
                </Box>
            </Dialog>
        }
        </React.Fragment>
    );
};

