/*
    Modal that runs when runs have been submitted for clustering
*/

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import Grow from '@material-ui/core/Grow';

import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        minWidth: '30%',
        padding: '50px 100px 50px 100px',
    },
    columnLayout: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    headingContainer: { 
        width: '100%',
        textAlign: 'center',
    },
    headingText: {
        fontSize: 24,
        fontWeight: 800,
    },
    subheadingContainer: {
        width: '100%',
        textAlign: 'center',
        marginTop: 10
    },
    bodyText: {
        fontSize: 18,
    },

    progressContainer: {
        width: '100%',
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 40,
    },
}));

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function ComputeModal(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { open, onNumber, totalNumber, error } = props;

    return (
        <Dialog
            open = {open}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <Box className={classes.columnLayout}>
                { error === '' ?
                    <React.Fragment>
                        <Container className={classes.headingContainer}>
                            <Typography className={classes.headingText}>Computation in Progress</Typography>
                        </Container>
                        <Container className={classes.subheadingContainer}>
                            <Typography className={classes.bodyText}>Please do not leave this page!</Typography>
                        </Container>
                        <Container className={classes.progressContainer}>
                            <Typography className={classes.bodyText}>{`Completed ${onNumber} of ${totalNumber}...`}</Typography>
                        </Container>
                        <LinearProgress />
                    </React.Fragment> :
                    <React.Fragment>
                        <Container className={classes.headingContainer}>
                            <Typography className={classes.headingText}>Oops! Something went wrong</Typography>
                        </Container>
                        <Container className={classes.subheadingContainer}>
                            <Typography className={classes.bodyText}>Note the error below and refresh the page.</Typography>
                        </Container>
                        <Container className={classes.subheadingContainer}>
                            <Typography className={classes.bodyText}>{error}</Typography>
                        </Container>
                    </React.Fragment>
                }
            </Box>
        </Dialog>
    );
};

