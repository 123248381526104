/*
    General component that is used for every single tab
    within a single run. Used for navigation between different tabs
*/

import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import axios from 'axios';
import clsx from 'clsx';
import qs from 'qs';

import {paths} from '../../PathContext';

import RunDetailCrossTabComponent from './RunDetailCrossTabComponent';
import RunDetailSummaryComponent from './RunDetailSummaryComponent';
import RunDetailOverviewComponent from './RunDetailOverviewComponent';
import RunDeleteComponent from './RunDeleteComponent';
import RunDetailTypingToolComponent from './RunDetailTypingToolComponent';
import RunSaveConfirmationModal from './RunSaveConfirmationModal';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = theme => ({
    tabContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 50,
    },
    runNav: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        marginTop: 10,
        alignItems: 'center',
        marginBottom: 20,
    },
    returnButton: {
        padding: '10px 30px 10px 30px',
        marginRight: 20,
        textTransform: 'none'
    },
    returnText: {
        marginLeft: 5,
        fontSize: 18,
    },
    tertiaryButton: {
        backgroundColor: theme.palette.tertiary.dark,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.main
        },
        color: 'white',
        textTransform: 'none',
        fontSize: 16,
        padding: '10px 30px 10px 30px',
        marginLeft: 20,
        marginRight: 20,
    },
    primaryButton: {
        marginRight: theme.spacing(1),
        textTransform: 'none',
        fontSize: 16,
        padding: '10px 30px',
        backgroundColor: theme.palette.dark.main,
        '&:hover': {
            backgroundColor: theme.palette.dark.light
        },
    },
    tertiaryButtonSelected: {
        backgroundColor: theme.palette.tertiary.main
    },
    downloadBox: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    secondaryButton: {
        marginRight: theme.spacing(1),
        color: 'white',
        fontSize: 16,
        textTransform: 'none',
        padding: '10px 30px 10px 30px',
    },


    tableHeight: {
        maxHeight: '85vh',
        overflowX: 'scroll'
    },
    table: {
    },
    cellStyle: {
        border: '1px solid rgba(224, 224, 224, 1)',
        maxWidth: '10vw',
        minWidth: '5vw',
        fontSize: 16,
    }
})

const generalHeaders = ['Dimension', 'Factor', 'Variable', 'Response']

class RunDetailComponent extends React.Component {
    constructor(props) {
        super();

        this.state = {
            tab: 0,
            name: '',
            clusterNum: 0,
            alpha: 100,
            description: '',
            cluster: [],
            profile: [],
            metrics: [],
            oriCluster: [],
            oriProfile: [],
            oriMetrics: [],

            clusterLen: 0,
            profileLen: 0,
            metricsLen: 0,

            crosstabProfile: [],
            crosstabCluster: [],
            crosstabMetrics: [],

            columnSortData: [],

            summaryCluster: [],
            amvList: [],

            order: [],
            sortableHeaders: [],
            headerDescriptions: [],
            filterCriteriaIndex: '',
            filterThresholdValue: '',
            filterCriteriaValue: '',
            filterThresholdIndex: '',
            sortRowsBy: '',
            sortRowsDir: 'asc',
            sortColsBy: -1,
            sortColsDir: 'asc',

            oriOrder: [],
            oriFilterCriteriaIndex: '',
            oriFilterThresholdValue: '',
            oriFilterCriteriaValue: '',
            oriFilterThresholdIndex: '',
            oriSortRowsBy: '',
            oriSortRowsDir: 'asc',
            oriSortColsBy: -1,
            oriSortColsDir: 'asc',

            deleteOpen: false,

            variableDict: [],
            variableHeaders: [],

            editMode: false, // for general edits on top bar
            varEdited: false, // for changes to variables

            anchorEl: null, // for the download menu

            openSaveModal: false, // for when trying to download unsaved (the modal open state)
            downloadType: '' // either csv or xlsx
        }
        this.changeTab = this.changeTab.bind(this);
        this.checkEdit = this.checkEdit.bind(this);
        this.downloadCrosstab = this.downloadCrosstab.bind(this);
        this.downloadCrosstabExcel = this.downloadCrosstabExcel.bind(this);
        this.handleDuplicate = this.handleDuplicate.bind(this);
        this.setOrder = this.setOrder.bind(this);
        this.setFilterCriteriaValue = this.setFilterCriteriaValue.bind(this);
        this.setFilterThresholdValue = this.setFilterThresholdValue.bind(this);
        this.setFilterCriteriaIndex = this.setFilterCriteriaIndex.bind(this);
        this.setFilterThresholdIndex = this.setFilterThresholdIndex.bind(this);
        this.setSortRowsBy = this.setSortRowsBy.bind(this);
        this.setSortRowsDir = this.setSortRowsDir.bind(this);
        this.setSortColsBy = this.setSortColsBy.bind(this);
        this.setSortColsDir = this.setSortColsDir.bind(this);
        this.setDeleteOpen = this.setDeleteOpen.bind(this);
        this.setHeaderDescriptions = this.setHeaderDescriptions.bind(this);
        this.sortWithIndices = this.sortWithIndices.bind(this);
        this.pingLoad = this.pingLoad.bind(this);
        this.pingDownload = this.pingDownload.bind(this);
        this.saveConfiguration = this.saveConfiguration.bind(this);
        this.setCluster = this.setCluster.bind(this);
        this.setProfile = this.setProfile.bind(this);
        this.setMetrics = this.setMetrics.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleSaveModalClose = this.handleSaveModalClose.bind(this);
        this.resetEditMode = this.resetEditMode.bind(this);
    }

    // continuously ping until finished
    async pingLoad(jobID) {
        await axios.get(paths.apiResultPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'finished') {
                this.setState({
                    variableDict: response.data.result.cluster_dict,
                    variableHeaders: response.data.result.variable_headers,
                })
            }
            else {
                await new Promise(resolve => setTimeout(resolve, 100)); // ping every 0.1 seconds
                await this.pingLoad(jobID)
            }

        })
    }

    async componentDidMount() {
        this.props.setLoading(true)
        await axios.get(paths.apiRunLoad+this.props.runID+'/').then(async (response) => {
            // get the sortable header names
            var sortableHeaders = [];

            if (typeof(response.data) === 'string') {
                response.data = JSON.parse(response.data)
            }
            response.data.order.forEach((num, index) => {
                sortableHeaders.push('SEG'+(index + 1))
            });
            sortableHeaders[sortableHeaders.length-1] = 'Total';

            const columnSortData = [];

            var metricsLen = -1;
            // parse through the metrics first
            response.data.crosstabMetrics.forEach(dimension => {
                dimension.factors.forEach(factor => {
                    factor.variables.forEach(variable => {
                        variable.rows.forEach(row => {
                            columnSortData.push({
                                name: variable.code + '---' + row.name,
                                values: row.value
                            })
                        })
                        metricsLen += 1
                    })
                })
            })

            // make a list for easily sorting over entire list for absolute/marginal
            const amvList = []
            // then cluster (also format this for summary tab)
            const summaryCluster = []
            // track the number of variables selected
            var clusterLen = 0;
            response.data.crosstabCluster.forEach(dimension => {
                var factorArr = []
                var factorLen = 0
                dimension.factors.forEach(factor => {
                    var variableArr = [];
                    factor.variables.forEach(variable => {
                        var rowArr = [];
                        rowArr.push(variable.rows[0])
                        rowArr.push(variable.rows[1])
                        variableArr.push({
                            name: variable.name,
                            code: variable.code,
                            length: 3,
                            rows: rowArr
                        })
                        amvList.push({
                            name: variable.name,
                            code: variable.code,
                            length: 3,
                            rows: rowArr
                        })

                        variable.rows.forEach(row => {
                            columnSortData.push({
                                name: variable.code + '---' + row.name,
                                values: row.value
                            })
                        })
                        clusterLen += 1
                    });
                    factorArr.push({
                        name: factor.name,
                        length: variableArr.length * 3 + 1,
                        variables: variableArr
                    })
                    factorLen += variableArr.length * 3 + 1
                })
                summaryCluster.push({
                    name: dimension.name,
                    length: factorLen + 1,
                    factors: factorArr
                })
            })

            var profileLen = 0
            response.data.crosstabProfile.forEach(dimension => {
                dimension.factors.forEach(factor => {
                    factor.variables.forEach(variable => {
                        variable.rows.forEach(row => {
                            columnSortData.push({
                                name: variable.code + '---' + row.name,
                                values: row.value
                            })
                        })
                        profileLen += 1
                    })
                })
            })

            await this.pingLoad(response.data.job_id)

            this.setState({
                name: response.data.name,
                clusterNum: response.data.clusterNum,
                profile: response.data.profile,
                metrics: response.data.metrics,
                cluster: response.data.cluster,

                oriProfile: response.data.profile,
                oriMetrics: response.data.metrics,
                oriCluster: response.data.cluster,

                alpha: response.data.alpha,
                description: response.data.description,
                crosstabCluster: response.data.crosstabCluster,
                crosstabProfile: response.data.crosstabProfile,
                crosstabMetrics: response.data.crosstabMetrics,

                clusterLen: clusterLen,
                profileLen: profileLen,
                metricsLen: metricsLen,

                summaryCluster: summaryCluster,
                amvList: amvList,
                columnSortData: columnSortData,

                order: response.data.order,
                headerDescriptions: response.data.header_descriptions,
                sortableHeaders: sortableHeaders,
                filterCriteriaValue: response.data.filterCriteriaValue,
                filterThresholdValue: response.data.filterThresholdValue,
                filterCriteriaIndex: response.data.filterCriteriaIndex,
                filterThresholdIndex: response.data.filterThresholdIndex,
                sortRowsBy: response.data.sortRowsBy,
                sortRowsDir: response.data.sortRowsDir,
                sortColsBy: response.data.sortColsBy,
                sortColsDir: response.data.sortColsDir,


                oriOrder: response.data.order,
                oriFilterCriteriaValue: response.data.filterCriteriaValue,
                oriFilterThresholdValue: response.data.filterThresholdValue,
                oriFilterCriteriaIndex: response.data.filterCriteriaIndex,
                oriFilterThresholdIndex: response.data.filterThresholdIndex,
                oriSortRowsBy: response.data.sortRowsBy,
                oriSortRowsDir: response.data.sortRowsDir,
                oriSortColsBy: response.data.sortColsBy,
                oriSortColsDir: response.data.sortColsDir,
            })
        })
        this.props.setLoading(false)
    }

    // change the logic around for saving
    changeTab = async (tabId) => {
        if (this.state.tab !== tabId) { 
            await this.props.setLoading(true)
            await this.setState({tab: tabId})
        }
    }

    // make sure things have, indeed, changed
    // notice: the input var are the variable initials
    checkEdit({o=this.state.order, fcv=this.state.filterCriteriaValue, ftv=this.state.filterThresholdValue,
                     fci=this.state.filterCriteriaIndex, fti=this.state.filterThresholdIndex,
                     srb=this.state.sortRowsBy, srd=this.state.sortRowsDir,
                     scb=this.state.sortColsBy, scd=this.state.sortColsDir}) {

        var edited = JSON.stringify(o) !== JSON.stringify(this.state.oriOrder)
        edited = edited || (fcv !== this.state.oriFilterCriteriaValue)
        edited = edited || (ftv !== this.state.oriFilterThresholdValue)
        edited = edited || (fci !== this.state.oriFilterCriteriaIndex)
        edited = edited || (fti !== this.state.oriFilterThresholdIndex)
        edited = edited || (srb !== this.state.oriSortRowsBy)
        edited = edited || (srd !== this.state.oriSortRowsDir)
        edited = edited || (scb !== this.state.oriSortColsBy)
        edited = edited || (scd !== this.state.oriSortColsDir)

        return edited
    }

    /*
        Set all the crosstab changes
    */
    setOrder = (arr) => {
        const edited = this.checkEdit({o: arr});
        this.setState({order: arr, editMode: edited || this.state.varEdited})
    }
    setFilterCriteriaValue = (val) => {
        const edited = this.checkEdit({fcv: val});
        this.setState({filterCriteriaValue: val, editMode: edited || this.state.varEdited})
    }
    setFilterThresholdValue = (val) => {
        const edited = this.checkEdit({ftv: val});
        this.setState({filterThresholdValue: val, editMode: edited || this.state.varEdited})
    }
    setFilterCriteriaIndex = (val) => {
        const edited = this.checkEdit({fci: val});
        this.setState({filterCriteriaIndex: val, editMode: edited || this.state.varEdited})
    }
    setFilterThresholdIndex = (val) => {
        const edited = this.checkEdit({fti: val});
        this.setState({filterThresholdIndex: val, editMode: edited || this.state.varEdited})
    }
    setSortRowsBy = (val) => {
        const edited = this.checkEdit({srb: val});
        this.setState({sortRowsBy: val, editMode: edited || this.state.varEdited})
    }
    setSortRowsDir = (val) => {
        const edited = this.checkEdit({srd: val});
        this.setState({sortRowsDir: val, editMode: edited || this.state.varEdited})
    }
    // here, sorting is used to make sure that we can check the editable
    setCluster = (arr) => {
        this.setState({cluster: arr, editMode: true, varEdited: true})
    }
    setProfile = (arr) => {
        this.setState({profile: arr, editMode: true, varEdited: true})
    }
    setMetrics = (arr) => {
        this.setState({metrics: arr, editMode: true, varEdited: true})
    }
    setDeleteOpen = () => { this.setState({deleteOpen: !this.state.deleteOpen})}
    setHeaderDescriptions = (val) => { this.setState({headerDescriptions: val})}

    handleSaveModalClose = () => {this.setState({openSaveModal: false})}
    resetEditMode = () => {this.setState({editMode: false, varEdited: false})}


    // sort array and get indices (make sure to send a sliced/copy! or it WILL sort the original as well)
    // dir must be either 'asc' or 'desc'
    sortWithIndices = (toSort, dir) => {
        for (var i = 0; i < toSort.length; i++) {
            toSort[i] = [toSort[i], i+1]; // +1 for segment groups
        }
        // sort situationally
        toSort.sort(function(left, right) {
            if (dir === 'asc') {
                return left[0] < right[0] ? -1 : 1;
            }
            else {
                return left[0] < right[0] ? 1 : -1;
            }
        });
        toSort.sortIndices = [];
        for (var j = 0; j < toSort.length; j++) {
            toSort.sortIndices.push(toSort[j][1]);
            toSort[j] = toSort[j][0];
        }
        return toSort;
    }

    setSortColsBy =  async (ind) => {
        const edited = this.checkEdit({scb: ind});
        if (ind === '') {
            // revert to original sorting
            await this.setState({
                order: Array.from(Array(this.state.order.length), (_, i) => i + 1), // revert to 1,2,3,...
                sortColsBy: ind,
                editMode: edited || this.state.varEdited,
            })
        }
        else {
            const sorted = this.sortWithIndices(this.state.columnSortData[parseInt(ind)].values.slice(), this.state.sortColsDir)
            await this.setState({
                order: sorted.sortIndices,
                sortColsBy: ind, // set the order to be based on the index
                editMode: edited || this.state.varEdited,
            })
        }
    }
    setSortColsDir = async (sortDir) => {
        const edited = this.checkEdit({scd: sortDir});
        // if empty
        if (this.state.sortColsBy === '') {
            this.setState({
                sortColsDir: sortDir,
                editMode: edited || this.state.varEdited,
            })
        }
        // if not empty (selected something to sort by)
        else {
            const sorted = this.sortWithIndices(this.state.columnSortData[parseInt(this.state.sortColsBy)].values.slice(), sortDir)
            
            this.setState({
                order: sorted.sortIndices,
                sortColsDir: sortDir,
                editMode: edited || this.state.varEdited,
            })
        }
    }

    pingDownload = async (jobID) => {
        await axios.get(paths.apiFileDownloadPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'pending' || response.data.status === 'undefined') {
                await new Promise(resolve => setTimeout(resolve, 500)); // ping every 1 second
                await this.pingDownload(jobID)
            }
            else {
                window.location.href = response.config.url
            }
        })
    }

    pingDownloadExcel = async (jobID) => {
        await axios.get(paths.apiFileExcelDownloadPing + jobID + '/').then(async (response) => {
            if (response.data.status === 'pending' || response.data.status === 'undefined') {
                await new Promise(resolve => setTimeout(resolve, 500)); // ping every 1 second
                await this.pingDownloadExcel(jobID)
            }
            else {
                window.location.href = response.config.url
            }
        })
    }

    // download crosstab as a csv
    // bypass lets you download without necessarily saving
    downloadCrosstab = async (bypass=false) => {
        // confirm changes, as needed
        if (this.state.editMode && !bypass) {
            this.setState({openSaveModal: true, downloadType: 'csv'})
            return
        }
        this.props.setLoading(true)
        await axios.get(paths.apiRunDownloadCrosstab + this.props.runID + '/').then(async (response) => {
            await this.pingDownload(response.data.job_id)
        })
        this.props.setLoading(false)
    }

    // download raw seg as a csv
    downloadSegProb = async () => {
        this.props.setLoading(true)
        await axios.get(paths.apiRunDownloadRawProb + this.props.runID + '/').then((response) => {
            window.location.href = response.config.url
        })
        this.props.setLoading(false)
    }

    // download as a 
    downloadCrosstabExcel = async (bypass=false) => {
        if (this.state.editMode && !bypass) {
            this.setState({openSaveModal: true, downloadType: 'xlsx'})
            return
        }
        this.props.setLoading(true)
        await axios.get(paths.apiRunDownloadCrosstabExcel + this.props.runID + '/').then(async (response) => {
            await this.pingDownloadExcel(response.data.job_id)
        })
        this.props.setLoading(false)
    }

    saveConfiguration = async () => {
        this.props.setLoading(true)
        await axios.post(paths.apiRunSaveConfig + this.props.runID + '/', qs.stringify({
            order: JSON.stringify(this.state.order),
            filterCriteriaValue: this.state.filterCriteriaValue,
            filterThresholdValue: this.state.filterThresholdValue,
            filterCriteriaIndex: this.state.filterCriteriaIndex,
            filterThresholdIndex: this.state.filterThresholdIndex,
            sortRowsBy: this.state.sortRowsBy,
            sortRowsDir: this.state.sortRowsDir,
            sortColsBy: this.state.sortColsBy,
            sortColsDir: this.state.sortColsDir,

            cluster: JSON.stringify(this.state.cluster),
            profile: JSON.stringify(this.state.profile),
            metrics: JSON.stringify(this.state.metrics),
        })).then((response) => {
            this.setState({
                oriOrder: this.state.order,
                oriFilterCriteriaValue: this.state.filterCriteriaValue,
                oriFilterThresholdValue: this.state.filterThresholdValue,
                oriFilterCriteriaIndex: this.state.filterCriteriaIndex,
                oriFilterThresholdIndex: this.state.filterThresholdIndex,
                oriSortRowsBy: this.state.sortRowsBy,
                oriSortRowsDir: this.state.sortRowsDir,
                oriSortColsBy: this.state.sortColsBy,
                oriSortColsDir: this.state.sortColsDir,
                editMode: false,
                varEdited: false,

                oriCluster: this.state.cluster,
                oriProfile: this.state.profile,
                oriMetrics: this.state.metrics,
            })
        })
        this.props.setLoading(false)
    }

    // when the duplicate run button is pressed, pass these states as a JSON to RunDesignComponent
    handleDuplicate = async () => {
        await this.saveConfiguration() // save the work
        this.props.duplicateRun({
            name: this.state.name,
            clusterNum: this.state.clusterNum,
            cluster: this.state.cluster,
            profile: this.state.profile,
            metrics: this.state.metrics,
            description: this.state.description,
            alpha: this.state.alpha
        })
    }

    handleMenuOpen = (event) => {
        this.setState({anchorEl: event.currentTarget})
    }

    handleMenuClose = (event) => {
        this.setState({anchorEl: null})
    }

    render() {
        const { classes } = this.props;
        return (
        <Box className={classes.tabContent}>
            <RunDeleteComponent
                open={this.state.deleteOpen}
                handleClose={this.setDeleteOpen}
                name={this.state.name}
                runID={this.props.runID}
                setRunID={this.props.setRunID}/>
            <RunSaveConfirmationModal
                open={this.state.openSaveModal}
                handleClose={this.handleSaveModalClose}
                saveConfiguration={this.saveConfiguration}
                downloadType={this.state.downloadType}
                downloadCrosstab={this.downloadCrosstab}
                downloadCrosstabExcel={this.downloadCrosstabExcel}/>
            <Box className={classes.runNav}>
                <Button
                    className={classes.returnButton}
                    onClick={() => this.props.setRunID('')}>
                    <ArrowBackIcon fontSize="small"/><Typography className={classes.returnText}>Return to List</Typography>
                </Button>
                <Button
                    variant='contained'
                    className={clsx({[classes.tertiaryButton]: true, [classes.tertiaryButtonSelected]: this.state.tab === 0})}
                    onClick={() => this.changeTab(0)}>
                    Cross-Tab
                </Button>
                <Button
                    variant='contained'
                    className={clsx({[classes.tertiaryButton]: true, [classes.tertiaryButtonSelected]: this.state.tab === 1})}
                    onClick={() => this.changeTab(1)}>
                    Summary
                </Button>
                <Button
                    variant='contained'
                    className={clsx({[classes.tertiaryButton]: true, [classes.tertiaryButtonSelected]: this.state.tab === 2})}
                    onClick={() => this.changeTab(2)}>
                    Run Details
                </Button>
                <Button
                    variant='contained'
                    className={clsx({[classes.tertiaryButton]: true, [classes.tertiaryButtonSelected]: this.state.tab === 3})}
                    onClick={() => this.changeTab(3)}>
                    Typing Tool
                </Button>
                <Box className={classes.downloadBox}>
                    { this.state.tab < 2 &&
                        <React.Fragment>
                            { this.state.editMode === true &&
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.primaryButton}
                                    onClick={this.saveConfiguration}>
                                    <SaveIcon/><Typography> Save</Typography>
                                </Button>
                            }
                            <Button
                                variant='contained'
                                color='secondary'
                                className={classes.secondaryButton}
                                onClick={this.handleMenuOpen}>
                                <GetAppRoundedIcon/>Download File<ExpandMoreIcon/>
                            </Button>
                            <Menu
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.handleMenuClose}>
                                <MenuItem onClick={this.downloadSegProb}>Raw Segment Probabilities</MenuItem>
                                <MenuItem onClick={() => this.downloadCrosstab(false)}>Crosstab Unformatted .csv</MenuItem>
                                <MenuItem onClick={() => this.downloadCrosstabExcel(false)}>Crosstab Formatted .xlsx</MenuItem>
                            </Menu>
                        </React.Fragment>
                    }
                    { this.state.tab === 2 &&
                        <React.Fragment>
                            <Button
                                variant='contained'
                                color='primary'
                                className={classes.primaryButton}
                                onClick={this.setDeleteOpen}>
                                <Typography>Delete Run</Typography>
                            </Button>
                            <Tooltip title='Changes will be auto-saved'>
                            <Button
                                variant='contained'
                                color='secondary'
                                className={classes.secondaryButton}
                                onClick={this.handleDuplicate}>
                                <Typography>Duplicate as Run</Typography>
                            </Button>
                            </Tooltip>
                        </React.Fragment>
                    }
                </Box>
            </Box>

            { this.state.tab === 0 &&
                <RunDetailCrossTabComponent
                    name={this.state.name}
                    generalHeaders={generalHeaders}
                    sortableHeaders={this.state.sortableHeaders}
                    crosstabCluster={this.state.crosstabCluster}
                    crosstabProfile={this.state.crosstabProfile}
                    crosstabMetrics={this.state.crosstabMetrics}
                    columnOrder={this.state.order}
                    headerDescriptions={this.state.headerDescriptions}
                    filterCriteriaValue={this.state.filterCriteriaValue}
                    filterThresholdValue={this.state.filterThresholdValue}
                    filterCriteriaIndex={this.state.filterCriteriaIndex}
                    filterThresholdIndex={this.state.filterThresholdIndex}
                    sortRowsBy={this.state.sortRowsBy}
                    sortRowsDir={this.state.sortRowsDir}
                    sortColsBy={this.state.sortColsBy}
                    sortColsDir={this.state.sortColsDir}
                    columnSortData={this.state.columnSortData}

                    setOrder={this.setOrder}
                    setFilterCriteriaValue={this.setFilterCriteriaValue}
                    setFilterThresholdValue={this.setFilterThresholdValue}
                    setFilterCriteriaIndex={this.setFilterCriteriaIndex}
                    setFilterThresholdIndex={this.setFilterThresholdIndex}
                    setSortRowsBy={this.setSortRowsBy}
                    setSortRowsDir={this.setSortRowsDir}
                    setSortColsBy={this.setSortColsBy}
                    setSortColsDir={this.setSortColsDir}
                    setHeaderDescriptions={this.setHeaderDescriptions}
                    setLoading={this.props.setLoading}
                    runID={this.props.runID}

                    cluster={this.state.cluster}
                    profile={this.state.profile}
                    metrics={this.state.metrics}
                    setCluster={this.setCluster}
                    setProfile={this.setProfile}
                    setMetrics={this.setMetrics}
                />
            }
            { this.state.tab === 1 &&
                <RunDetailSummaryComponent
                    name={this.state.name}
                    generalHeaders={generalHeaders}
                    sortableHeaders={this.state.sortableHeaders}
                    crosstabCluster={this.state.summaryCluster}
                    amvList={this.state.amvList}
                    crosstabProfile={this.state.crosstabProfile}
                    crosstabMetrics={this.state.crosstabMetrics}
                    columnOrder={this.state.order}
                    headerDescriptions={this.state.headerDescriptions}
                    filterCriteriaValue={this.state.filterCriteriaValue}
                    filterThresholdValue={this.state.filterThresholdValue}
                    filterCriteriaIndex={this.state.filterCriteriaIndex}
                    filterThresholdIndex={this.state.filterThresholdIndex}
                    sortRowsBy={this.state.sortRowsBy}
                    sortRowsDir={this.state.sortRowsDir}
                    sortColsBy={this.state.sortColsBy}
                    sortColsDir={this.state.sortColsDir}
                    columnSortData={this.state.columnSortData}

                    setOrder={this.setOrder}
                    setFilterCriteriaValue={this.setFilterCriteriaValue}
                    setFilterThresholdValue={this.setFilterThresholdValue}
                    setFilterCriteriaIndex={this.setFilterCriteriaIndex}
                    setFilterThresholdIndex={this.setFilterThresholdIndex}
                    setSortRowsBy={this.setSortRowsBy}
                    setSortRowsDir={this.setSortRowsDir}
                    setSortColsBy={this.setSortColsBy}
                    setSortColsDir={this.setSortColsDir}
                    setHeaderDescriptions={this.setHeaderDescriptions}

                    setLoading={this.props.setLoading}
                    runID={this.props.runID}

                    cluster={this.state.cluster}
                    profile={this.state.profile}
                    metrics={this.state.metrics}
                    setCluster={this.setCluster}
                    setProfile={this.setProfile}
                    setMetrics={this.setMetrics}
                />
            }
            { this.state.tab === 2 &&
                <RunDetailOverviewComponent
                    name={this.state.name}
                    clusterNum={this.state.clusterNum}

                    crosstabCluster={this.state.crosstabCluster}
                    crosstabProfile={this.state.crosstabProfile}
                    crosstabMetrics={this.state.crosstabMetrics}

                    description={this.state.description}
                    setLoading={this.props.setLoading}
                    runID={this.props.runID}

                    clusterLength={this.state.clusterLen}
                    profileLength={this.state.profileLen}
                    metricsLength={this.state.metricsLen}
                />
            }
            { this.state.tab === 3 &&
                <RunDetailTypingToolComponent
                    name={this.state.name}

                    variableDict={this.state.variableDict}
                    variableHeaders={this.state.variableHeaders}
                    headerDescriptions={this.state.headerDescriptions}

                    columnOrder={this.state.order}

                    setLoading={this.props.setLoading}
                    runID={this.props.runID}
                />
            }
        </Box>)
    }
}

export default withStyles(useStyles)(RunDetailComponent)
