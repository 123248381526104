/*
    Modal that confirms deletion of the project
*/

import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Dialog,
        DialogContent,
        DialogContentText,
        IconButton,
        Typography,
        Grow,
        Button,
        Container} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';

import {paths} from '../../PathContext';

import Loading from '../Loading';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        minWidth: 400,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    submitButtonWrapper: {
        width: '50%',
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
    },
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function DeleteProjectModal(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { open, handleClose, name, projectID } = props;

    const [loading, setLoading] = useState(false);

    const deleteConfirm = async () => {
        setLoading(true)
        await axios.get(paths.apiProjectDelete + projectID + '/').then((response) => {
            window.location.href = paths.home
        })
        setLoading(false)
    }

    return (
        <Dialog
            open = {open}
            onClose = {handleClose}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <Loading open={loading}/>
            <DialogTitle classes={classes} id="alert-about-title" onClose = {handleClose}>
                {"Delete Project"}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-about-description">
                    You are about to delete {name}. This will delete all the associated runs as well - make sure to save any relevant files locally.<br/>
                </DialogContentText>
                <DialogContentText id="alert-about-description2">
                    This action is irreversible. Are you sure?
                </DialogContentText>
            </DialogContent>
            <Container className={classes.fieldView}>
                <Button
                    variant='contained'
                    color='primary'
                    classes={{contained: classes.submitButton}}
                    className={classes.submitButtonWrapper}
                    onClick={deleteConfirm}>
                    <Typography className={classes.submitFontSize}>Delete</Typography>
                </Button>
            </Container>
        </Dialog>
    );
};

