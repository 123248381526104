/*
    General project page and combines the header (MainHeader) and the body (ProjectContent)
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    Slide,
} from '@material-ui/core';
import MainHeader from './subcomponents/MainHeader';
import ProjectContent from './subcomponents/ProjectContent';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        minWidth: 1000,
        height: '100%',
    },
}))

export default function Project(props) {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <MainHeader />
            <Slide direction='left' in = {true} mountOnEnter unmountOnExit>
            <Box>
                <ProjectContent {...props} />
            </Box>
            </Slide>
        </Box>
    );
}