import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { Link } from "@reach/router"
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import qs from 'qs'
import { paths } from '../../PathContext';
import axios from 'axios'

import Loading from '../Loading'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    // for the register/login section
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        marginBottom: 20,
    },
    subheading: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10
    },
    subheadingtitle: {
        fontSize: 20,
        fontFmaily: 'Source Sans Pro',
        marginTop: 'auto',
        marginBottom: 10,
        marginLeft: 10,
    },
    textInput: {
        width: '100%',
        marginTop: 10,
    },
    buttonWrapper: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginTop: 10,
        paddingLeft: 0,
        paddingRight: 0,
    },
    submitButtonWrapper: {
        width: '50%',
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    submitFontSize: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 14
    },
    errorText: {
        fontSize: 14,
        color: 'red',
        marginBottom: 10,
    }
}))

export default function PreLogForgotPW(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [successEmail, setSuccessEmail] = useState('')
    const [error, setError] = useState('')

    const [loading, setLoading] = useState(false)

    const runPing = async (jobID) => {
        await axios.get(paths.apiStatusPing + jobID + '/').then(async (response) => {
            if (response.data.status !== 'finished') {
                await new Promise(resolve => setTimeout(resolve, 500)); // ping every 1 second
                await runPing(jobID)
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        await axios.post(paths.forgotPassword, qs.stringify({
            email: e.target.email.value
        })).then(async (response) => {
            await runPing(response.data.job_id)
            setSuccessEmail(response.data.email)
        }, (error) => {
            setError(error.response.data.error_message)
        })

        setLoading(false);
    }

    return (
        <React.Fragment>
            <Loading open={loading}/>
            <GoogleReCaptcha />
            <Box className={classes.subheading}>
                <Link to={paths.login}>
                    <IconButton>
                        <ArrowBackIcon />
                    </IconButton>
                </Link>
                <Typography className={classes.subheadingtitle}>Forgot Password</Typography>
            </Box>
            { successEmail &&
                <Container className={classes.contentContainer}>
                    <Typography>An email with instructions to reset password was sent to {successEmail}.</Typography>
                </Container>
            }
            { !successEmail && 
                <form onSubmit={handleSubmit}>
                    <Container className={classes.contentContainer}>
                        <TextField
                            variant='outlined'
                            label='Email'
                            name='email'
                            autoComplete='off'
                            className={classes.textInput}
                        />
                        
                        <Container className={classes.buttonWrapper}>
                            {error && <Typography className={classes.errorText}>{error}</Typography>}
                            <Button
                                variant='contained'
                                color='primary'
                                classes={{contained: classes.submitButton}}
                                className={classes.submitButtonWrapper}
                                type='submit'>
                                <Typography className={classes.submitFontSize}>Reset Password</Typography>
                            </Button>
                        </Container>
                    </Container>
                </form>
            }
        </React.Fragment>
    );
}