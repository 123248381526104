/*
    The top of the React stack. Here, the application routes to different parts
    of the application depending on the URL. The theme is also specified here.

    Note: GoogleReCaptcha v3 is utilized only in pages where users are not
    authenticated. Users not logged in will be sent to login page

    General structure:
        Login: The login page (unauthenticated users)
        ForgotPW: Fill in inputs to send email verification  (unauth users)
        ResetPW: Reset the password (needs a valid key) (unauth users)
        Home: Landing page after logging in
        Project: Project page, whcih includes the associated Run components
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Router } from "@reach/router"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { paths } from './PathContext';
import Login from './Login';
import ForgotPW from './ForgotPW'
import ResetPW from './ResetPW'
import Home from './Home';
import Project from './Project'

// styles
const theme = createTheme({
    palette: {
        primary: {
            light: '#2FA3CF',
            main: '#4D85C5',
        },
        secondary: {
            main: '#FF8F00',
        },
        tertiary: {
            light: '#d4f94c',
            main: '#9FC600',
            dark: '#6c9500'
        },
        dark: {
            light: '#0c47a6',
            main: '#09347A',
        }
    },
    typography: {
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: 16,
          }
        }
  }
});

ReactDOM.render(

    <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}>
        <ThemeProvider theme={theme}>
            <Router>
                <Login exact path={paths.login} />
                <ForgotPW exact path={paths.forgotPassword} />
                <Home exact path={paths.home} />
                <ResetPW path={paths.resetPassword+':keyVal'}/>
                <Project path={paths.project+':projectID'}/>
            </Router>
        </ThemeProvider>
    </GoogleReCaptchaProvider>,
    document.getElementById('root')
);