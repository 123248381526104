/*
    Modal for creating a new project.
*/

import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

import axios from 'axios';
import {paths} from '../../PathContext';

import Loading from '../Loading';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        width: 500,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center'
    },
    fieldInput: {
        marginTop: 10,
        marginBottom: 20,
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        marginTop: 5,
    },
    submitButtonWrapper: {
        width: '30%',
        marginTop: 10,
        marginBottom: 10,
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    submitFontSize: {
        textTransform: 'none',
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 16
    }, 

    uploadBox: {
    },

    uploadText: {
        fontFamily: 'Open Sans, Source Sans Pro, sans-serif',
        fontSize: 20,
        color: theme.palette.primary.main,
        marginTop: 'auto',
        marginBottom: 'auto',

        '&:hover': {
            color: theme.palette.secondary.main,
            cursor: 'pointer',
        },
    },
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function ProjectAdd(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { open, handleClose } = props;

    const [ errorName, setErrorName ] = useState(false)
    const [ errorDataFile, setErrorDataFile ] = useState(false)
    const [ errorVariableFile, setErrorVariableFile ] = useState(false)
    const [ errorDiscretizedFile, setErrorDiscretizedFile ] = useState(false)
    const [ errorServer, setErrorServer ] = useState(false)
    const [ errorServerMessage, setErrorServerMessage ] = useState('')
    const [ projectName, setProjectName ] = useState('')

    const [ filesArray, setFilesArray ] = useState([])

    const [loading, setLoading] = useState(false)

    const handleNameChange = (e) => {
        setProjectName(e.target.value)
    }

    const closeModalReset = () => {
        handleClose()
        setProjectName('')
        setFilesArray([])
        setErrorServer(false)
        setErrorVariableFile(false)
        setErrorDataFile(false)
        setErrorName(false)
        setErrorDiscretizedFile(false)
    }

    /*
    All the helper functions for the upload component
    */
    const handleSubmit = async (files, allFiles) => {
        var name_check = false
        for (var i = 0; i < props.data.length; i++) {
            if (props.data[i].name === projectName) {
                name_check = true;
                break;
            }
        }

        const data_filter = files.filter((file) => /^data_.*csv$/.test(file.meta.name))
        const variables_filter = files.filter((file) => /^variables_.*csv/.test(file.meta.name))
        const discretized_filter = files.filter((file) => /^discretized.*csv/.test(file.meta.name))

        const data_check = data_filter.length < 1
        const variable_check = variables_filter.length < 1
        const discretized_check = discretized_filter.length < 1

        setErrorDataFile(data_check)
        setErrorVariableFile(variable_check)
        setErrorDiscretizedFile(discretized_check)
        setErrorName(name_check)

        if (!data_check && !variable_check && !name_check && !discretized_check) {
            setLoading(true);
            const formData = new FormData();
            formData.append('name', projectName);
            formData.append('data_file', data_filter[0].file);
            formData.append('variable_file', variables_filter[0].file)
            formData.append('discretized_file', discretized_filter[0].file)

            await axios.post(paths.apiProjectList, formData)
                .then((response) => {
                    window.location.href = response.data.success
                }, (error) => {
                    setErrorServer(true)
                    setErrorServerMessage(error.response.data.error_message)
                })

            setLoading(false);
        }
        //setLoading(true)
    }

    const handleChangeStatus = ({meta, remove}, status) => {
        let updatedFiles = [...filesArray]
        if (status === 'done') {
            updatedFiles.push(meta);
        }
        else if (status === 'removed') {
            updatedFiles = filesArray.filter((file) => {
                return file.name !== meta.name;
            });
        }
        else if (status === 'error_validation') {
            remove()
        }
        setFilesArray(updatedFiles)
    }

    const handleValidation = ({meta}) => {
        return filesArray.find(e => e.name === meta.name);
    }

    const CustomLayout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });
        return (
        <div {...dropzoneProps} >
            {previewsToDisplay}
            {files.length < 3 && input}
            {files.length > 0 && submitButton}
        </div>
        );
    };

    return (
        <Dialog
            open = {open}
            onClose = {closeModalReset}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <Loading open={loading}/>
            <DialogTitle classes={classes} id="alert-about-title" onClose = {closeModalReset}>
                {"New Project"}
            </DialogTitle>

            <DialogContent dividers>
                <Container className={classes.fieldView}>    
                    <TextField
                        label='Project Name'
                        variant='outlined'
                        name='name'
                        fullWidth
                        className={classes.fieldInput}
                        onChange={handleNameChange}
                        value={projectName}/>
                    <DialogContentText id="alert-criteria-description">
                        Please submit your data and variables files below.
                    </DialogContentText>
                    <Dropzone
                        accept=".csv"
                        validate={handleValidation}
                        onSubmit={handleSubmit}
                        onChangeStatus={handleChangeStatus}

                        maxFiles={3}
                        inputWithFilesContent={`${3 - filesArray.length} more`}
                        submitButtonDisabled={filesArray.length < 3}
                        submitButtonContent='Create'

                        LayoutComponent={CustomLayout}/>

                    { errorName && <Typography className={classes.errorText}>A project with this name already exists.</Typography>}
                    { errorDataFile && <Typography className={classes.errorText}>Missing data file. Please follow the naming convention: data_*.csv.</Typography>}
                    { errorVariableFile && <Typography className={classes.errorText}>Missing variable file. Please follow the naming convention: variables_*.csv.</Typography>}
                    { errorDiscretizedFile && <Typography className={classes.errorText}>Missing discretization file. Please follow the naming convention: discretized_*.csv.</Typography>}
                    { errorServer && <Typography className={classes.errorText}>{errorServerMessage}</Typography>}
                </Container>
            </DialogContent>
        </Dialog>
    );
};

