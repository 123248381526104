/*
    Modal for updating the description of a segment header
    Used in crosstab and summary tabs
*/

import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Dialog,
        DialogContent,
        TextField,
        IconButton,
        Typography,
        Grow,
        Button,
        Container} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import qs from 'qs';

import {paths} from '../../PathContext';

import Loading from '../Loading';

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: 10,
        minWidth: 450,
    },
    title: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: 'white',
    },
    header: {
        fontSize: 18,
        fontWeight: 800,
        color: 'white',
        marginLeft: 10,
        fontFamily: 'Source Sans Pro, Open Sans, sans-serif',
    },
    submitButtonWrapper: {
        width: '50%',
    },
    submitButton: {
        backgroundColor: theme.palette.dark.main
    },
    fieldView: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        padding: 10,
    },
    error: {
        fontSize: 16,
        textAlign: 'center',
        color: 'red',
    }
}));

const DialogTitle = (props => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.title} {...other}>
            <Typography className={classes.header}>{children}</Typography>
                {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

// To make the dialog modal slide
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function RunUpdateHeaderDescription(props) {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { open, handleClose, runID, headerDescriptions, orderNum, setHeaderDescriptions } = props;

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [descriptions, setDescriptions] = useState(headerDescriptions);

    // push to the database
    const updateDatabase = async () => {
        setLoading(true)
        await axios.post(paths.apiRunUpdateDescriptions + runID + '/', qs.stringify({
            descriptions: JSON.stringify(descriptions)
        })).then((response) => {
            setHeaderDescriptions(descriptions)
            handleClose()
        }, (error) => {
            setError(error.response.data.error_message)
        })
        setLoading(false)
    }

    // update this specific index with the input value
    const updateDescription = (val) => {
        var copy = descriptions.slice()
        copy[orderNum] = val
        setDescriptions(copy)
    }

    return (
        <Dialog
            open = {open}
            onClose = {handleClose}
            TransitionComponent = {Transition}
            aria-labelledby="alert-about-title"
            aria-describedby="alert-about-description"
            classes={{paper: classes.root}}
        >
            <Loading open={loading}/>
            <DialogTitle classes={classes} id="alert-about-title" onClose = {handleClose}>
                {"Update Description"}
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    rowsMax={6}
                    value={descriptions[orderNum]}
                    variant='outlined'
                    onChange={(e) => updateDescription(e.target.value)}
                />
            </DialogContent>
            <Container className={classes.fieldView}>
                {error !== '' && <Typography className={classes.error}>{error}</Typography>}
                <Button
                    variant='contained'
                    color='primary'
                    classes={{contained: classes.submitButton}}
                    className={classes.submitButtonWrapper}
                    onClick={updateDatabase}>
                    <Typography className={classes.submitFontSize}>Update</Typography>
                </Button>
            </Container>
        </Dialog>
    );
};

